export const UltrasoundAScanData = [
  {
    id: 1,
    model: "AL-4000",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "AL-4000",
        name: "Ultrasound A-Scan + Pachymeter",
        main_points: [
          "Extremely handy & compact",
          "Easy to use",
          "Multiple IOL power calculation",
          "Contact & Immersion mode",
          "Bluetooth connectivity",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/al-4000.pdf",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/al-4000@2x.png"],
        video: [
          {
            videoSrc: "/images/cataract/video_1.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Scanmate A",
    data: [
      {
        id: 1,
        logo: "/images/cataract/DGH.png",
        model: "Scanmate A DGH 600",
        name: "The innovative DGH 6000 A-Scan (Scanmate A) offers clinicians an unmatched level of usability and accuracy.",
        main_points: [
          "Ultra portable",
          "Unique measurement guidance",
          "Unique grading algorithm",
          "Contact & immersion mode",
          "Compression lock-out",
          "Multiple IOL power calculation",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/scanmate-a.pdf",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/scanmate-a@2x.png"],
        video: [
          {
            videoSrc: "/images/cataract/scanmatethumb.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
          },
        ],
      },
    ],
  },
];

export const opticalBiometerData = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "OA 2000",
    name: "Swept Source Optical Biometer with Topography",
    main_points: [
      "Fully automatic",
      "Eye tracking system",
      "Fast & accurate",
      "Wide & touch screen",
      "Modern IOL power calculations",
      "Barrette Site",
      "Ray Tracing (Optional)",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publicationss",
      },
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/oa-2000.pdf",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/oae@2x.png"],
    imgGallery: [
      "/images/cataract/optical.png",
      "/images/cataract/OA2000_02.png",
      "/images/cataract/OA-2000_main-unit.png",
      "/images/cataract/OA-2000.png",
      "/images/cataract/OA 2000.png",
    ],
    // videoData: [
    //   {
    //     videothumbnail: "/images/cataract/overview.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Cameraaaa",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //   {
    //     videothumbnail: "/images/cataract/video_2.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //   {
    //     videothumbnail: "/images/cataract/overview.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //    {
    //     videothumbnail: "/images/cataract/video_2.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    // ],
    video: [
      {
        videothumbnail: "/images/cataract/video_2.png",
        videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
        videoTitle: "Product Description",
        videoDesc:
          "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      },
      // {
      //   videothumbnail: "/images/cataract/OA-2000.png",
      //   videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
      //   videoTitle: "User Guide",
      //   videoDesc:
      //     "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      // },
      // {
      //   videothumbnail: "/images/cataract/OA-2000.png",
      //   videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
      //   videoTitle: "Technical Overview",
      //   videoDesc:
      //     "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      // },
      // Add more videos as needed
    ],
  },
];

export const wavefront = [
  {
    id: 1,
    logo: "/images/rectina/csologo.png",
    model: "OSIRIS-T Aberrometer + Topographer ",
    name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publicationss",
      },
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/osiris-t.pdf",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/osiris-t-1@2x.png"],
    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Overview Screen",
      },
    ],
  },
];

export const selectiveLaser = [
  {
    id: 1,
    logo: "/images/cataract/capsu.png",
    model: "CAPSULASER",
    name: "CAPSULaser is a selective laser that creates a capsulotomy in less than a 1/3ʳᵈ of a second.",
    main_points: [
      "Laser precision Capsulotomy in size & circularity",
      "Free floating Capsulotomy",
      "360° IOL coverage",
      "Capsulotomy size from 4.0 to 5.5 mm",
      "Rolled over edge",
      "Elastic and tear resistance capsule",
      "Seamless integration in to Cataract work flow",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publicationss",
      },
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/capsulaser-brochure-hr.pdf",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/capsulaser-front-panel.png"],
    advantage: [
      {
        id: 1,
        name: "THE CAPSULASER ADVANTAGE",
        points: [
          {
            id: 1,
            img: "/images/cataract/adv_5.svg",
            desc: "Consistence Capsulotomies in every surgery",
          },
          {
            id: 2,
            img: "/images/cataract/adv_4.svg",
            desc: "Laser precision for size and centration",
          },
          {
            id: 3,
            img: "/images/cataract/adv_3.svg",
            desc: "An elastic capsulotomy rim resistant to tearing",
          },
          {
            id: 4,
            img: "/images/cataract/adv_2.svg",
            desc: "A small ergonomic microscope mounted laser device",
          },
          {
            id: 5,
            img: "/images/cataract/adv_1.svg",
            desc: "CAPSULASER provides a cost & time effective solution",
          },
        ],
      },
    ],

    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/selectivethumb.png",
        videoUrl: "https://youtu.be/CuPHd0V9cgI",
        videoTitle: "Surgical Video",
      },
    ],
  },
];

export const surgical = [
  {
    id: 1,
    logo: "/images/cataract/mani.png",
    model: "Mani Ophthalmic Knife",
    name: "Ophthalmic Knifes & Trocars",
    main_points: [
      "Ultimate sharpness",
      "Less induced Astigmatism",
      "Less tissue damage",
      "Faster patient recovery",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publicationss",
      },
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/mani.pdf",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/surgical-blades@2x.png"],
    video: [
      {
        videoSrc: "/images/cataract/surgical_video.png",
        videoUrl: "https://youtu.be/CuPHd0V9cgI",
      },
    ],
  },
];

export const OtTableData = [
  {
    id: 1,
    model: "500 XLE",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "500 XLE",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Intuitive manual keypad",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/500-XLE.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/500-XLE.png",
              "/images/cataract/500_XLE_POI_1.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/500_XLE_POI_2-1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/cataract/500_XLE_POI_einspaltig.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/cataract/500_XLE_Slider_2_3-1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
            ],
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "SurgiLine",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "SurgiLine",
        name: "Innovative surgeon’s chair",
        main_points: [
          "Individually adaptable to the surgeon",
          "Comfort and foamed seat",
          "Unrestricted mobility",
          "Intuitive foot padel for height adjustment",
          "All around movable arm rest",
          "Emergency stop button for safe & smooth operation",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/SurgiLine.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/SurgiLine.png",
              "/images/cataract/SurgiLine_POI_2.png",
              "/images/cataract/SurgiLine_POI_1.png",
            ],
            images: [
              "/images/cataract/OTtablechair_1.png",
              "/images/cataract/OTtablechair_2.png",
            ],
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
];
const dhakad = {
  name: "Deepak",
};
