import React, { useState } from "react";
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-marquee-slider";

const CustomArrow = ({ direction, onClick }) => (
  <button
    className={`absolute z-10 md:w-[91px] w-[40px] h-[40px] md:h-[91px] flex items-center justify-center bg-white rounded-full focus:outline-none ${
      direction === "left" ? "md:left-[73.61vw] left-[0vw]" : "md:right-[7.29vw] right-[0vw]"
    } md:top-[-8vw] top-[40vw]`}
    onClick={onClick}
    aria-label={direction === "left" ? "Previous slide" : "Next slide"}
  >
    <div
      className="absolute inset-0 bg-cover bg-center"
      style={{
        backgroundImage: `url(${
          direction === "left"
            ? "/icon/leftsliderarrow.svg"
            : "/icon/rightsliderarrow.svg"
        })`,
      }}
    ></div>
    <div className="absolute z-20">
      {direction === "left" ? (
        <HiChevronLeft className="text-black w-[6vw] h-[6vw]" />
      ) : (
        <HiChevronRight className="text-black w-[6vw] h-[6vw]" />
      )}
    </div>
  </button>
);

const ImageSlider = ({ data }) => {
  const [isPaused, setIsPaused] = useState(false);

  const slickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />,
  };

  return (
    <div
      className="w-full flex md:py-90px-vw py-40px-mvw  px-16px-vw md:px-0 items-center justify-center"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* For Mobile: react-slick Slider */}
      <div className="md:hidden w-full relative">
        <Slider {...slickSettings}>
          {data.map((imgSrc, index) => (
            <div key={index}>
              <img
                src={imgSrc}
                alt={`imgGallery-${index}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* For Desktop: Marquee */}
      <div className="hidden md:flex items-center justify-center">
        <Marquee
          velocity={50}
          minScale={0.8}
          className="partner-marquee"
          pauseOnHover={isPaused}
        >
          {data.map((imgSrc, index) => (
            <img
              key={index}
              src={imgSrc}
              alt={`imgGallery-${index}`}
              className="md:max-w-[24.23vw] md:max-h-[24.23vw] md:mr-[6.25vw] object-cover"
            />
          ))}
        </Marquee>
      </div> 

      
    </div>
  );
};

export default ImageSlider;
