import React from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import Listing from "../../components/category/Listing";

const Cataract = () => {
  const listingData = [
    {
      id: "01",
      name: "Ultrasound A-Scan",
      img: "/images/cataract/cat-6.png",
      link: "/products/cataract/ultrasound-a-scan",
    },
    {
      id: "02",
      name: "Optical Biometer",
      img: "/images/cataract/cat-5.png",
      link: "/products/cataract/optical-biometer",
    },
    {
      id: "03",
      name: "Wavefront Aberrometer",
      img: "/images/cataract/cat-4.png",
      link: "/products/cataract/wavefront-aberrometer",
    },
    {
      id: "04",
      name: "Selective Laser Capsulotomy",
      img: "/images/cataract/pavel.png",
      link: "/products/cataract/selective-laser-capsulotomy",
    },
    {
      id: "05",
      name: "Surgical Blades",
      img: "/images/cataract/cat-2.png",
      link: "/products/cataract/surgical-blades",
    },
    {
      id: "06",
      name: "OT Table & Chair",
      img: "/images/cataract/cat-1.png",
      link: "/products/cataract/OTTable-Chair",
    },
  ];

  return (
    <section className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract"}
        centerHeading={"Cataract"}
      />
      <Listing heading={"Cataract Products"} data={listingData} />
    </section>
  );
};

export default Cataract;
