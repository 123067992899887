export const navlinks = [
  {
    id: 1,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 2,
    name: "Our Products",
    // link: "/products",
  },
  {
    id: 3,
    name: "Contact Us",
    link: "/contact-us",
  },
];

export const ourProductsSublinks = [
  {
    id: 1,
    name: "Retina & Glaucoma",
    link: "/products/retina-glaucoma",
  },
  {
    id: 2,
    name: "Cornea & Refractive",
    link: "/products/cornea-refractive",
  },
  {
    id: 3,
    name: "Cataract",
    link: "/products/cataract",
  },
  {
    id: 4,
    name: "Primary Diagnostics",
    link: "/products/primary-diagonostics",
  },
  {
    id: 5,
    name: "Sterilization & Indicators",
    link: "/products/sterilization-indicators",
  },
];
