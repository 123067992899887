import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { surgical } from "../../../Data/Cataract";

const Surgical = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Surgical Blades"}
        centerHeading={"Surgical Blades"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-80px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {surgical.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-y-40px-mvw md:gap-y-0 grid-cols-1 ">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="flex items-start justify-start">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS :
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto md:max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            <>
              <div className="w-full gap-x-16px-vw md:pt-[3vw] mx-auto gap-y-16px-mvw md:gap-y-0 py-60px-mvw md:py-0 md:flex-row flex-col flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
              {item.video &&
                item.video.map((videoItem, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-col items-center justify-center md:pt-[6.25vw]"
                  >
                    <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                    </h1>
                    <div className="md:mt-60px-vw flex flex-col items-start relative">
                      {/* Only Image */}
                      <img
                        src={videoItem.videoSrc} // Thumbnail image source
                        alt="video thumbnail"
                        className="md:max-w-[42.24vw] block"
                      />
                    </div>
                  </div>
                ))}
            </>
          </div>
        ))}
      </section>
    </main>
  );
};

export default Surgical;
