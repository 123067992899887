import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { OtTableData } from "../../../Data/Cataract";
import BottomSlider from "../../slider/BottomSlider";
import ImageSlider from "../../slider/ImageSlider";
import CategorySelector from "../../category/CategorySelector";

const OTTable = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const activeCategory = OtTableData[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | OT Table & Chair"}
        centerHeading={"OT Table & Chair"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={OtTableData}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 md:px-[7.29vw] px-16px-mvw grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col pt-20px-mvw md:pt-0 font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="flex items-center justify-center md:max-w-full max-w-[20%]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-start  gap-x-20px-vw">
                    <div className="flex-shrink-0 bg-[#519A7F] md:mt-[1vw] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center  ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-full max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full  md:px-[19vw] gap-x-16px-vw md:pt-[0vw]  mx-auto md:flex-row py-60px-mvw md:py-0 flex-col gap-y-16px-mvw md:gap-y-0 flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  {item.imggallery.length > 3 ? (
                    <ImageSlider data={item.imggallery} />
                  ) : (
                    <div className="hidden md:my-40px-vw md:flex items-center justify-center">
                      {item.imggallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:max-h-[24.23vw] md:mr-[6.25vw] object-cover"
                        />
                      ))}
                    </div>
                  )}

                  {/* Separator Line */}
                  {/* <div className="absolute left-0 md:px-[7.29vw] md:w-full md:mb-[4vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                  {/* <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                        IMAGE & VIDEO GALLERY
                      </h1> */}

                  {/* Video and Image Navigation */}
                  {item.videoData ? (
                    <BottomSlider slides={item.videoData} />
                  ) : (
                    <div className="md:pt-60px-vw flex flex-col items-start relative">
                      <div className="flex items-center md:gap-x-30px-vw">
                        {item.images.map((videoSrc, index) => (
                          <div key={index} className="mb-8 ">
                            {" "}
                            {/* Added margin-bottom for spacing between items */}
                            <img
                              src={videoSrc}
                              alt={`video-${index}`} // Updated alt tag to make it unique
                              className="md:max-w-[42.24vw] block"
                            />
                            <div className="md:mt-15px-vw text-[#2B2A29] font-poppins font-medium md:text-32px-vw text-16px-mvw">
                              Product Description
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default OTTable;
