/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Button from "../common/Button";
import Odometer from "odometer";
import "odometer/themes/odometer-theme-minimal.css";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import Marquee from "react-marquee-slider";
import CountUp from "react-countup";

const AboutSection = () => {
  const odometerRefs = useRef([]);
  const odometer25Ref = useRef(null);
  const navigate = useNavigate();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const Teamdata = [
    {
      id: 1,
      data: 7000,
      desc: "Ophthalmologists Trust Our Products & Services",
    },
    {
      id: 2,
      data: 200,
      desc: "Experienced and well trained Employees at your service",
    },
    {
      id: 3,
      data: 30,
      desc: "Prestigious awards for the best distributor performance",
    },
    {
      id: 4,
      data: 15,
      desc: "International partnerships to bring you the best technologies in the world of Ophthalmology",
    },
  ];

  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log("Entry:", entry); // Debug visibility
          if (entry.isIntersecting && !hasAnimated) {
            setHasAnimated(true); // Trigger the animation
          }
        });
      },
      { threshold: 0.5 }
    );

    const target = document.querySelector(".team-section");
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [hasAnimated]);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="w-full md:pt-[4vh] py-0  md:px-[6.77vw] px-4 odometer-section">
      {/* about us heading para and button  */}
      <div
        className="w-full  grid md:grid-cols-[auto_1fr_auto] gap-x-100px-vw pt-70px-mvw md:pt-0 grid-cols-1 md:gap-y-20px-vh gap-y-20px-mvw  place-items-start "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="justify-self-start  ">
          <SectionHeading title={"ABOUT US"} />
        </div>
        <div className="w-full  max-w-full  ">
          <ParaText
            paratext={
              "Established in 1996, My Healthskape Medicals Private Limited has been a leader in sourcing ophthalmic surgical equipments and diagnostic devices from various reputable international suppliers specializing in the ophthalmic industry. We focus on bringing the advanced eye care technology to the Indian market, ensuring that healthcare professionals have access to the best diagnostic equipment, refractive lasers, and surgical instruments. Our success is built on a foundation of well-trained professionals who provide exceptional service. We pride ourselves on being service partners for a lifetime, committed to supporting healthcare professionals with the tools they need to deliver outstanding patient care."
            }
          />
        </div>
        <div className="md:justify-self-end !justify-self-center mt-[25%] hidden md:block">
          <Button title={"Know More"} onClick={() => navigate("/about-us")} />
        </div>
      </div>

      {/* about us content  */}
      <div
        className="grid md:grid-cols-2 grid-cols-1  pt-20px-mvw md:pt-0"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {/* left section  */}
        <div className="flex flex-col items-start md:mt-40px-vw md:gap-y-30px-vh  gap-y-10px-mvw ">
          <div className="counter-section font-poppins font-bold text-[#519A7F] leading-none md:mt-40px-vw text-[25vw] md:text-[17.71vw]">
            <div className="md:tracking-normal flex items-center tracking-tighter">
              <CountUp
                start={0}
                end={25} // Replace with dynamic value if needed
                duration={4}
                separator=","
                enableScrollSpy // Automatically animates when visible
                scrollSpyOnce // Runs animation only once
                easingFn={(t, b, c, d) => {
                  // Custom easing function for smoother animation
                  t /= d;
                  t--;
                  return c * (t * t * t + 1) + b;
                }}
              />{" "}
              <span>+</span>
            </div>
          </div>
          <div className="">
            <SectionHeading title={"Mission: Servicing Vision"} />
          </div>
          <div className="hidden md:block  w-full md:max-w-[80%]">
            <ParaText
              paratext={
                "At My Healthskape, our vision is encapsulated in the phrase Servicing Vision.We are dedicated to advancing the field of ophthalmic technology by providing innovative solutions that improve patient outcomes and streamline medical practices. Our mission is to support eye care professionals with cutting-edge tools that ensure precision and reliability in medical procedures."
              }
            />
          </div>
          <div className=" md:hidden block w-full ">
            <ParaText
              paratext={
                "MyHealthskape has been at the forefront of bringing you the path breaking innovative technologies in the field of Ophthalmology from across the globe."
              }
            />
          </div>
        </div>

        {/* <div className=" hidden w-full md:grid grid-cols-2 md:mt-[6vw]  relative place-items-start ">
          {Teamdata.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-start gap-y-3px-vh ${
                index === 1 || index === 3 ? "mt-80px-vh" : ""
              } relative`}
            >
            
              <div className="text-[#4E5152] font-poppins font-bold opacity-100 md:text-[3.125vw] text-[10vw] flex items-center tracking-tight">
                <h1
                  className="flex items-center justify-center odometer"
                  ref={(el) => (odometerRefs.current[index] = el)} 
                >
                  0
                </h1>
                <span>+</span>
              </div>
              <p className="text-[#2B2A29] font-semibold md:text-22px-vw text-40px-vw opacity-[0.8] md:max-w-[80%] font-raleway">
                {item.desc}
              </p>
            
              {index === 0 && (
                <div className="absolute left-0 w-[89%] -bottom-70px-vh  border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
              {index === 1 && (
                <div className="absolute -left-45px-vw w-[92%] -bottom-75px-vh  border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
              {index === 0 && (
                <div className="absolute right-45px-vw h-[325%]  border-r-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
            </div>
          ))}
        </div> */}

        <div className="team-section hidden w-full md:grid grid-cols-2 md:mt-[6vw] relative place-items-start">
          {Teamdata.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-start gap-y-3px-vh ${
                index === 1 || index === 3 ? "mt-80px-vh" : ""
              } relative`}
            >
              {/* CountUp and Description */}
              <div className="text-[#4E5152] font-poppins font-bold opacity-100 md:text-[3.125vw] text-[10vw] flex items-center tracking-tight">
                <CountUp
                  start={0}
                  end={hasAnimated ? item.data : item.data} // Always use `item.data` as fallback
                  duration={4}
                  separator=","
                  enableScrollSpy
                  scrollSpyOnce
                  easingFn={(t, b, c, d) => {
                    t /= d;
                    t--;
                    return c * (t * t * t + 1) + b;
                  }}
                />
                <span>+</span>
              </div>
              <p className="text-[#2B2A29] font-semibold md:text-22px-vw text-40px-vw opacity-[0.8] md:max-w-[80%] font-raleway">
                {item.desc}
              </p>
              {/* Conditionally rendering the border */}
              {index === 0 && (
                <div className="absolute left-0 w-[89%] -bottom-70px-vh border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
              {index === 1 && (
                <div className="absolute -left-45px-vw w-[92%] -bottom-75px-vh border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
              {index === 0 && (
                <div className="absolute right-45px-vw h-[325%] border-r-2 opacity-10 border-[#2B2A29] z-10"></div>
              )}
            </div>
          ))}
        </div>

        <div className=" md:hidden flex items-center gap-x-40px-mvw  w-full">
          <Marquee velocity={20} minScale={0.8} className="">
            {Teamdata.map((item, index) => (
              <div
                key={index}
                className="flex max-w-[50vw] flex-col mr-40px-mvw  my-30px-mvw items-start"
              >
                <div className="text-[#4E5152] font-poppins font-bold opacity-100 text-44px-mvw flex items-center tracking-tight">
                  {item.data}+
                </div>
                <p className="text-[#2B2A29] font-semibold text-16px-mvw opacity-[0.8]  font-raleway">
                  {item.desc}
                </p>
              </div>
            ))}
          </Marquee>
        </div>

        <div className=" !justify-self-center block text-center  md:hidden py-20px-mvw ">
          <Button title={"Know More"} onClick={() => navigate("/about-us")} />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
