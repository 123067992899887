import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import { pachymeter } from "../../../Data/RectinaGlaucoma";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import BottomSlider from "../../slider/BottomSlider";
import CategorySelector from "../../category/CategorySelector";

const Pachymeter = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const activeCategory = pachymeter[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | Pachymeter"}
        centerHeading={"Pachymeter"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={pachymeter}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  grid-cols-1 gap-y-40px-mvw md:pt-40px-vw pt-24px-mvw md:gap-y-[2vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="max-w-[50%] md:max-w-full">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <>
                <div className="w-full  gap-x-16px-vw md:pt-[3vw] md:px-[14vw] mx-auto gap-y-16px-mvw md:gap-y-0 flex-col md:flex-row flex items-center justify-center">
                  <DownloadCard data={item.additionalInfo} />
                </div>
                {item.video && (
                  <div className="w-full flex flex-col items-center justify-center md:pt-[6.25vw] pt-60px-mvw">
                    {/* <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                      IMAGE & VIDEO GALLERY
                    </h1> */}

                    {/* <div className="w-full flex items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className=" w-full flex justify-center md:p-40px-vw items-center">
                        <img src={img} alt="" className="md:max-w-[25vw]" />
                      </div>
                    ))}
                  </div> */}

                    <div className=" flex  md:flex-row flex-col items-center gap-80px-vw relative">
                      {item.video.map((videoSrc, index) =>
                        videoSrc.videoData ? (
                          <BottomSlider slides={videoSrc.videoData} />
                        ) : (
                          <div key={index} className="mb-8 md:mt-60px-vw ">
                            <img
                              src={videoSrc}
                              alt={`video-${index}`} // Updated alt tag to make it unique
                              className="md:max-w-[42.24vw] block"
                            />
                            <div className="md:mt-15px-vw mt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-32px-vw text-16px-mvw">
                              Product Description
                            </div>
                          </div>
                        )
                      )}
                    </div>

                  
                  </div>
                )}
              </>
            }
          </div>
        ))}
      </section>
    </main>
  );
};

export default Pachymeter;
