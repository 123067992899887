import React, { useEffect } from "react";
import { navlinks, ourProductsSublinks } from "../../Data/Navliks";
import { CgChevronDown } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";

const MobileMenu = ({
  menuOpen,
  setMenuOpen,
  productsMenuOpen,
  setProductsMenuOpen,
}) => {
  useEffect(() => {
    const img = new Image();
    img.src = "/images/home/mobilebg.png";
    // Prevent scrolling by applying overflow: hidden to both html and body
    if (menuOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }

    // Cleanup function to reset overflow when the component is unmounted or menu closes
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [menuOpen]);

  const handleLinkClick = (itemName) => {
    if (itemName !== "Our Products") {
      setMenuOpen(false); // Close the menu for all other routes except "Our Products"
    }
  };

  return (
    <>
      {menuOpen && (
        <div className=" fixed top-0 left-0 !z-20 w-full h-full">
          <div className="absolute  left-0 right-0 bottom-0 h-full bg-black opacity-50 z-[-1]"></div>

          <div
            className={`md:hidden  absolute top-0  left-0 w-full `}
            style={{
              backgroundImage: `url("/images/home/mobilebg.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className={`w-full flex items-center justify-center pb-36px-mvw px-36px-mvw pt-[32.27vw] relative`}
            >
              <div className="rounded-lg space-y-20px-mvw w-full">
                {/* Add max-width for better display */}
                {navlinks.map((item) => (
                  <div key={item.name} className="relative">
                    <Link to={item.link}>
                      <button
                        className={`flex justify-between w-full items-center ${
                          item.name === "Our Products" && productsMenuOpen
                            ? "pb-0"
                            : "pb-18px-mvw"
                        } text-18px-mvw text-[#FFFFFF] brightness-150 font-poppins font-medium uppercase`}
                        onClick={() => {
                          if (item.name === "Our Products") {
                            setProductsMenuOpen(!productsMenuOpen);
                          }
                          handleLinkClick(item.name); // Call handleLinkClick for route change
                        }}
                      >
                        <span>{item.name}</span>
                        <div
                          className={`md:hidden ${
                            item.name === "Our Products" && productsMenuOpen
                              ? "hidden"
                              : "block"
                          } absolute left-0 bottom-0 border-white w-full md:mx-[6.24vw] border-b-[0.53vw] opacity-20 z-10`}
                        ></div>
                        {item.name === "Our Products" && (
                          <CgChevronDown
                            size={20}
                            className={`transform transition-transform duration-300 ${
                              productsMenuOpen ? "rotate-180" : ""
                            }`}
                          />
                        )}
                      </button>
                    </Link>
                    {/* Mobile Dropdown for Our Products */}
                    {item.name === "Our Products" && productsMenuOpen && (
                      <div className="mt-2 flex flex-col">
                        {ourProductsSublinks.map((sublink) => (
                          <Link
                            key={sublink.id}
                            to={sublink.link}
                            onClick={() => setMenuOpen(false)} // Close menu on sublink click
                            className="px-16px-mvw py-6px-mvw text-[#FFFFFF] brightness-150 font-poppins font-regular pb-12px-mvw text-16px-mvw transition duration-300"
                          >
                            {sublink.name}
                          </Link>
                        ))}
                        <div
                          className={`md:hidden ${
                            productsMenuOpen ? "block" : "hidden"
                          } absolute left-0 bottom-0 border-white w-full md:mx-[6.24vw] border-b-[0.53vw] opacity-20 z-10`}
                        ></div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
