import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import { oct } from "../../../Data/RectinaGlaucoma";
import Marquee from "react-marquee-slider";
import BottomSlider from "../../slider/BottomSlider";
import { motion, AnimatePresence } from "framer-motion";

import ImageSlider from "../../slider/ImageSlider";
import CategorySelector from "../../category/CategorySelector";

const Oct = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCategorySelect = (index) => {
    setActiveIndex(index);
  };

  const activeCategory = oct[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []); 
   
  
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | OCT"}
        centerHeading={"OCT"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={oct}
          activeCategoryIndex={activeIndex}
          onCategorySelect={handleCategorySelect}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full ">
            <div className="grid md:grid-cols-2 md:px-[7.29vw] px-16px-mvw gap-x-[6vw] grid-cols-1 gap-y-40px-mvw md:gap-y-0 py-40px-mvw md:py-0 md:pt-85px-vw md:pb-[7vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw md:gap-y-30px-vw">
                <div className="md:max-w-[15.63vw] max-w-[45.87vw]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-full max-h-full  object-contain"
                  />
                ))}
              </div>
            </div>

            <h1 className="font-raleway px-16px-mvw md:px-0  text-left md:text-center font-bold pt-30px-mvw md:pt-0 text-24px-mvw md:text-32px-vw text-[#2B2A29]">
              PRODUCT KEY FEATURES
            </h1>

            <div className="w-full h-full md:px-[7.29vw] px-16px-mvw md:my-60px-vw pt-20px-mvw md:pt-0 pb-20px-mvw md:pb-0 grid md:grid-cols-3 gap-20px-vw relative">
              {/* Left Grid */}
              <div className="grid md:grid-cols-1 md:pr-60px-vw gap-20px-vw">
                {item.keyFeatureObject.key_feature
                  .slice(
                    0,
                    item.keyFeatureObject.key_feature.length === 7 ? 3 : 4
                  ) // 3 items if 7 objects, otherwise 4
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-40px-vw pb-32px-mvw items-start justify-start"
                    >
                      <div className="flex items-center gap-x-20px-mvw md:gap-x-20px-vw">
                        <div className="bg-[#519A7F] w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-24px-vw text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw text-16px-mvw opacity-80 md:pt-16px-vw pt-12px-mvw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>

              {/* Center Image */}
              <div
                className={`hidden md:flex ${
                  item.keyFeatureObject.key_feature.length === 7
                    ? "flex-col md:items-center items-start justify-start md:justify-center"
                    : ""
                } gap-y-100px-vw w-full md:h-full relative`}
              >
                {/* <div className=""> */}
                <img
                  src={item.keyFeatureObject.commonImg}
                  alt="center"
                  className="w-full   h-full object-contain"
                />
                {/* </div> */}

                {/* 7th Object under Image */}
                {item.keyFeatureObject.key_feature.length === 7 && (
                  <div
                    key={item.keyFeatureObject.key_feature[6].id}
                    className="w-full flex flex-col items-center justify-center text-center"
                  >
                    <div className="flex items-center gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                        <img
                          src={item.keyFeatureObject.key_feature[6].img}
                          alt="icon"
                          className="w-full h-full object-contain"
                        />
                      </div> 
                      
                      <div className="text-24px-vw font-semibold text-[#2B2A29]">
                        {item.keyFeatureObject.key_feature[6].title}
                      </div>
                    </div>

                    <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw md:pt-16px-vw leading-relaxed">
                      {item.keyFeatureObject.key_feature[6].desc}
                    </div>
                  </div>
                )}
              </div>

              {/* Right Grid */}
              <div className="grid md:grid-cols-1 md:pl-60px-vw gap-20px-vw">
                {item.keyFeatureObject.key_feature
                  .slice(
                    item.keyFeatureObject.key_feature.length === 7 ? 3 : 4, // Start after 3 for 7 objects, otherwise after 4
                    item.keyFeatureObject.key_feature.length === 7 ? 6 : 8 // End at 6 for 7 objects, otherwise at 8
                  )
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-40px-vw pb-32px-mvw items-start justify-start"
                    >
                      <div className="flex items-center gap-x-20px-mvw md:gap-x-20px-vw">
                        <div className="bg-[#519A7F] w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-24px-vw text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw text-16px-mvw opacity-80 md:pt-16px-vw pt-12px-mvw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[7.29vw] px-16px-mvw  gap-x-16px-vw gap-y-20px-mvw md:gap-y-0 md:pt-[3vw] pt-20px-mvw  flex md:flex-row flex-col items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }

            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */} 
                  {/* <div className="md:mb-100px-vw"> */}
                  <ImageSlider data={item.imggallery} />
                  {/* </div> */}
                  {/* Separator Line */}
                  <div className="absolute left-0 md:w-[87%] md:mb-[12vw] md:mx-[7vw] pb-[4vw] border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                  {/* Video and Image Navigation slider */}
                  <BottomSlider slides={item.videoData} />
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Oct;
