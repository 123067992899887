import React from "react";
import { useNavigate } from "react-router-dom";

const routeMapping = {
  Home: "/",
  Products: "/",
  "Retina & Glaucoma": "/products/retina-glaucoma",
  OCT: "/products/retina-glaucoma/oct",
  Perimeters: "/products/retina-glaucoma/perimeters",
  ERG: "/products/retina-glaucoma/erg",
  Tonometer: "/products/retina-glaucoma/tonometer",
  "Cornea & Refractive": "/products/cornea-refractive",
  Topographer: "/products/cornea-refractive/topographer",
  "Sterilization & Indicators": "/products/sterilization-indicators",
  "Steam Sterilizers": "/products/sterilization-indicators/steam-sterilizers",
  Cataract: "/products/cataract",
  "Ultrasound A-Scan": "/products/cataract/ultrasound-a-scan",
  "Primary & Diagonistics": "/products/primary-diagonostics",
  "Slit-Lamp": "/products/primary-diagonostics/slit-lamp",
  Lensmeter: "/products/primary-diagonostics/lensmeter",
  Pachymeter: "/products/retina-glaucoma/pachymeter",
  "B-Scan": "/products/retina-glaucoma/b-scan",
  "Packaging Material": "/products/sterilization-indicators/packaging-material",
  Microkeratome: "/products/cornea-refractive/microkeratome",
  "Dry Eye": "/products/cornea-refractive/dry-eye",
  "About Us": "/about-us",
  "Contact Us": "/contact-us",
  FAQs: "/faqs",
  Investors: "/investors",
  "Privacy Policy": "/privacy-policy",
  "Terms & Conditions": "/terms-and-conditions",
};

const BreadCrumb = ({ title = "", customCss }) => {
  const navigate = useNavigate();

  const handleNavigation = (breadcrumb) => {
    const path = routeMapping[breadcrumb];
    if (path) {
      navigate(path);
    } else {
      console.error(`No route found for breadcrumb: ${breadcrumb}`);
    }
  };

  const breadcrumbItems = title.split(" | ").filter(Boolean); // Split title by "|"

  return (
    <div
      className={`text-white md:pl-[6.83vw] pl-16px-mvw
       md:pt-[19vh] pt-[25.33vw] font-poppins font-regular md:text-16px-vw text-12px-mvw opacity-60 ${customCss}`}
    >
      {breadcrumbItems.length > 0 ? (
        breadcrumbItems.map((item, index) => (
          <span
            key={index}
            className={`cursor-pointer ${
              index < breadcrumbItems.length - 1
                ? "after:content-['|'] after:mx-2"
                : ""
            }`}
            onClick={() => handleNavigation(item)}
          >
            {item}
          </span>
        ))
      ) : (
        <p className="text-red-500">No breadcrumbs to display</p>
      )}
    </div>
  );
};

export default BreadCrumb;
