import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import { packagingmaterial } from "../../../Data/SterilizationIndicators";
import Aos from "aos";
import CategorySelector from "../../category/CategorySelector";

const PackagingMaterial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const activeCategory = packagingmaterial[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={
          "Home | Products | Sterilization & Indicators | Packaging Material"
        }
        centerHeading={"Packaging Material"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={packagingmaterial}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-y-60px-mvw md:gap-y-0 grid-cols-1  md:py-80px-vw">
              <div className="flex flex-col font-poppins pt-20px-mvw md:pt-0 text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="md:max-w-full max-w-[50%]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div
                    className="w-full flex items-start gap-x-12px-mvw
                   md:gap-x-20px-vw"
                  >
                    <div className="bg-[#519A7F] flex-shrink-0 rounded-full md:mt-[1vw] mt-8px-mvw w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw md:max-w-[65%]">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="w-full flex flex-col items-start justify-start gap-y-40px-mvw
               md:gap-y-0"
              >
                {item.img.map((img, index) => (
                  <div key={index} className="flex items-start justify-start ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[12vw]  gap-x-16px-vw pt-60px-mvw md:pt-0 md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
          </div>
        ))}
      </section>
    </main>
  );
};

export default PackagingMaterial;
