import React, { useEffect } from "react";
import {
  BsArrowLeft,
  BsArrowRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Slider from "react-slick";
import Aos from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { px } from "framer-motion";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const OurHistory = () => {
  const historyData = [
    { id: 1, year: 1996, desc: "Inception of My Healthskape" },
    {
      id: 2,
      year: 2000,
      desc: "Introduced WaveLight Excimer Lasers (Germany) in India for the first time, a revolution in LASIK technology.",
    },
    {
      id: 3,
      year: 2002,
      desc: "Introduced Moria Microkeratomes (France), an integral part of the LASIK procedure.",
    },
    {
      id: 4,
      year: 2003,
      desc: "Partnered with Tomey (Japan) for a range of Ophthalmology diagnostic devices.",
    },
    {
      id: 5,
      year: 2004,
      desc: "Partnered with CSO (Italy), bringing world-leading Slit Lamps and Topographers.",
    },
    {
      id: 6,
      year: 2015,
      desc: "Launched Optopol Revo OCT (Poland), from the pioneers of Spectral Domain OCT.",
    },
    {
      id: 7,
      year: 2019,
      desc: "Introduced SBM (Italy) and ESW (France), bringing a wave of Dry-Eyes diagnostic and treatment solutions.",
    },
    {
      id: 8,
      year: 2023,
      desc: "Bringing Capsulaser (USA) to India, a revolutionary new Laser procedure for Cataract Surgery.",
    },
  ];

  useEffect(() => {
    Aos.init({ easing: "ease-in", duration: 1500, once: false });
  }, []);

  const CustomArrow = ({ onClick, direction }) => {
    return (
      <div
        onClick={onClick}
        className={`absolute top-[0vw] z-10 cursor-pointer ${
          direction === "left" ? "left-[-2vw]" : "right-[-2vw]"
        } transform top-[3.2vw] -translate-y-1/2 md:p-[0.8vw] p-[2vw] bg-[#ffff] border-[0.12vw] border-[#393939] rounded-full border-opacity-50 text-[#393939] font-bold`}
      >
        {direction === "left" ? (
          <>
            <BsArrowLeft className="hidden md:block md:text-[1vw]" />{" "}
            {/* Arrow for desktop */}
            <BiChevronLeft className="md:hidden text-[5.5vw]" />{" "}
            {/* Chevron for mobile */}
          </>
        ) : (
          <>
            <BsArrowRight className="hidden md:block md:text-[1vw]" />{" "}
            {/* Arrow for desktop */}
            <BiChevronRight className="md:hidden text-[5.5vw]" />{" "}
            {/* Chevron for mobile */}
          </>
        )}
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 600,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2000,
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />, 
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="w-full md:px-[7.29vw] md:pt-[6.25vw] pt-60px-mvw  "
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex md:flex-row flex-col items-start md:gap-x-55px-vw">
        <SectionHeading title={"OUR HISTORY"} customCss={"whitespace-nowrap"} />
        <ParaText
          paratext={
            "Established in 1996, My Healthskape Medicals Private Limited has been a leader in sourcing ophthalmic surgical equipment and diagnostic devices from various reputable international suppliers specializing in the ophthalmic industry."
          }
          CustomCss={"md:mt-0 mt-20px-mvw"}
        />
      </div>
      <div className="relative w-full py-60px-mvw md:py-0">
        <div className="border-[#393939] ml-[0.9vw]   border-[0.1vw] border-opacity-30 border-solid w-full mr-[3vw] absolute md:top-[3.1vw] top-[19vw]  z-0"></div>
        <Slider {...settings} className="">
          {historyData.map((item) => (
            <div
              key={item.id}
              className="flex flex-col  items-center text-[#050709] font-poppins justify-start gap-y-10px-vw w-full h-full md:h-[17vw] md:!w-[12vw] relative"
            >
              {/* Dot */}
              <div className="bg-[#519A7F] absolute rounded-full w-10px-mvw h-10px-mvw md:w-16px-vw md:h-16px-vw z-10 md:top-[2.8vw] top-[1.8vw]  left-[44vw] md:left-[5.5vw]"></div>

              {/* Year */}
              <div className="font-regular md:text-20px-vw text-16px-mvw text-center opacity-80 pt-28px-mvw md:pt-90px-vw">
                {item.year}
              </div>

              {/* Description */}
              <div className="font-medium w-full text-center md:text-20px-vw text-16px-mvw pt-16px-mvw md:pt-16px-vw opacity-80">
                {item.desc}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurHistory;
