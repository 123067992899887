import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { fundus } from "../../../Data/RectinaGlaucoma";

const Funduscamera = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | Fundus Camera"}
        centerHeading={"Fundus Camera"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {fundus.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  gap-y-40px-mvw md:gap-y-0 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full max-w-[30%]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((point, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start gap-12px-mvw md:gap-x-20px-vw"
                  >
                    <div className="flex-shrink-0 bg-[#519A7F] md:mt-[1vw] mt-10px-mvw  rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {point}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center">
                {item.img.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full   gap-x-16px-vw md:pt-[3vw] pt-60px-mvw gap-y-16px-mvw md:gap-y-0 mx-auto md:flex-row flex-col  flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item.video &&
              item.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>
                  <div className="w-full flex  gap-y-40px-mvw md:gap-y-0 flex-col md:flex-row items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className=" w-full flex  justify-center  items-center">
                        <img src={img} alt="" className="md:max-h-[25vw]" />
                      </div>
                    ))}
                  </div>

                  {/* <div className="absolute left-0 md:w-full  md:mt-40px-vw   border-b-2 opacity-10  border-[#2B2A29] z-10"></div> */}
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Funduscamera;
