import React, { useEffect, useState } from "react";
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";
import Slider from "react-slick";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";

const CustomArrow = ({ onClick, direction }) => {
  return (
    <div
      onClick={onClick}
      className={`absolute top-[50%] z-10 cursor-pointer ${
        direction === "left" ? "left-0" : "right-0"
      } transform top-[3.2vw] -translate-y-1/2 md:p-[0.8vw] p-[1.5vw] bg-[#ffff] border-[0.12vw] border-[#393939] rounded-full  text-[#393939] font-bold`}
    >
      {direction === "left" ? (
        <>
          <BiChevronLeft className="md:hidden text-[6vw]" />{" "}
          {/* Chevron for mobile */}
        </>
      ) : (
        <>
          <BiChevronRight className="md:hidden text-[6vw]" />{" "}
          {/* Chevron for mobile */}
        </>
      )}
    </div>
  );
};
const OurTeam = () => {
  const TeamData = [
    {
      id: 1,
      name: "MONISH BACHOO",
      img: "/images/about/Monish.png",
      desc: "Monish Bachoo has been a cornerstone of the success and growth of My Healthskape since its inception, more than 25 years ago. His unwavering dedication and foresight have made My Healthskape one of the largest importers of Ophthalmic Medical Equipment in India. He has proved to be a strong leader through his unbending belief that people and good relations are critical to the growth of a business. Whether within My Healthskape or with external clients, Monish Bachoo has been at the forefront of helping others see the vision and strategies necessary to achieve their goals.<br/> <br/>Monish's journey began as a passionate Production Engineer with a keen interest in healthcare. In 1996, leveraging his expertise in engineering, he founded MyHealthskape Medicals Private Limited. His venture commenced with the distribution of Hanshin Sterilizers, where his deep understanding of engineering principles played a crucial role in ensuring the highest standards of quality and efficiency in the products offered. <br/> <br/>Monish Bachoo is an experienced full stack marketer with years of proven track record in generating value and building brands. Apart from being an exceptional marketer and having staggering sales strategy prowess, Monish has always been a team player, and believes in transforming people into experienced professionals, ready to encounter unprecedented hurdles.<br/> <br/>Monish Bachoo is a visionary leader with an unwavering commitment to sustainability and the well-being of both his organization and its staff. Renowned for his exceptional strategic thinking and forward-thinking mindset, Monish has cultivated a unique focus on employees, driving the organization towards continuous growth and success. His outstanding capability to establish and maintain enduring relationships with customers has been pivotal in solidifying the organization’s reputation and ensuring long-term success.",
    },
    {
      id: 2,
      name: "SAMEER PAIGANKAR",
      img: "/images/about/Sameer.png",
      desc: "Sameer Paigankar is a highly dedicated and versatile thinker with extensive experience in steering start-ups and expanding businesses. With a career spanning over 25 years, he has consistently demonstrated a rapid pace of growth and success in establishing diverse enterprises within esteemed pharmaceutical organizations.<br/>  <br/> Sameer's journey began as a Management Trainee at Johnson & Johnson, where he quickly ascended the ranks to become a Product Manager. His career trajectory includes notable positions such as GM-Marketing at Morepen Labs, Marketing Manager at Smith & Nephew, and Member of the Board of Directors at Glenmark Pharmaceuticals. He has also served as a former director at Getz Pharma and Kinedex Healthcare and currently holds the position of Director at My Healthskape Medicals Private Limited.<br/> <br/> Throughout his career, Sameer has achieved significant milestones. At Glenmark Pharmaceuticals, he was instrumental in establishing the drug discovery infrastructure, API business, and the entire generic R&D backend. His sharp professional acumen and keen eye for detail have ensured that organizational objectives were met within record times. Whether leading large research teams to achieve scientific and organizational goals or launching branded generic businesses for Morepen Labs and achieving financial break-even within a year, Sameer's impact has been profound.<br/> <br/>Sameer's educational background includes a Bachelor's Degree in Pharmaceutical Sciences and a Master's in Business Administration from Mumbai University. His unique blend of scientific expertise and business acumen has been a driving force behind his success in the pharmaceutical industry.",
    },
    {
      id: 3,
      name: "SHEELA PAIGANKAR",
      img: "/images/about/Sheela.png",
      desc: "Sheela Paigankar believes in mentoring and fostering an environment of equal opportunity for all. She is committed to creating an inclusive workplace culture with an emphasis on work-life balance. Her passion for marketing, which is her forte, drives her zeal to offer unique strategies benefiting customers.<br/>  <br/>Sheela has developed path breaking marketing initiatives such as “Practice Development Program”, which have set new standards in the ophthalmology industry. Sheela Paigankar is dedicated to transforming lives through technology and is passionate about mentoring young professionals in the industry, and driving innovation through collaboration and continues learning. <br/> <br/> Sheela's educational background includes a Bachelor's Degree in Pharmacy and a Master's in Business Administration from Mumbai University. Sheela's professional journey began in the healthcare industry. In 1996, she founded My Healthskape, India's leading ophthalmic distributor, showcasing her strong work ethic and commitment to improving people's lives. My Healthskape partnered with Wavelight in Germany to introduce the EX500 excimer laser and its LASIK procedure to India, aiming to eliminate the need for spectacles. Her relentless focus on servicing vision has positioned My Healthskape as a market leader, offering top-quality equipment and services.<br/> <br/>Currently, she leads My Healthskape, Nutragenix, Nurexa Pharma Private Limited, and Healthskape Pharma Private Limited with precision and expertise. Throughout her career, she has achieved significant milestones and has efficiently led her teams. Her unique combination of vigilance and business acumen has been instrumental in her success in the ophthalmic business.",
    },
  ];
  const [isMobile, setIsMobile] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    // Check for mobile view based on window size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px as breakpoint for mobile
    };

    handleResize(); // Initial check on component mount
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });

    // Disable AOS when a member is selected
    if (selectedMember) {
      Aos.refresh(); // Refresh AOS to remove animations if selectedMember is not null
    }
  }, [selectedMember]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: selectedMember === null,
    autoplaySpeed: 3000,
    nextArrow: selectedTeamMember === null && !selectedMember && (
      <CustomArrow direction="right" />
    ),
    prevArrow: selectedTeamMember === null && !selectedMember && (
      <CustomArrow direction="left" />
    ),
    draggable: selectedMember ? false : true, // Disable dragging if a member is selected
    swipeToSlide: selectedMember ? false : true, // Disable swipe to slide if a member is selected

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleImageClick = (id) => {
    setSelectedTeamMember(id === selectedTeamMember ? null : id);
  };

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const handleClose = () => {
    setSelectedMember(null);
  };

  return (
    <div
      className="w-full md:px-[7.29vw]  py-60px-mvw md:py-0"
      data-aos={selectedMember ? "" : "fade-up"}
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <SectionHeading
        title={"OUR DIRECTORS"}
        customCss={"text-center md:mb-60px-vw mb-40px-mvw md:mt-100px-vw"}
      />

      <div className="w-full">
        {isMobile ? (
          <Slider {...settings}>
            {TeamData.map((item) => (
              <div
                key={item.id}
                className=" flex flex-col p-2px-mvw items-center justify-start"
              >
                <div
                  className={`border cursor-pointer relative hover:bg-white border-solid border-[#707070] border-opacity-40 md:rounded-[1.04vw] rounded-[5.33vw]`}
                  onClick={() => handleImageClick(item.id)}
                >
                  <img
                    src={item.img}
                    alt="doctor"
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute bottom-0 right-0 opacity-100 transition-opacity duration-300">
                    <img
                      src="/images/category/active_arrow.svg"
                      alt="arrow"
                      loading="lazy"
                    />
                    <div className="absolute right-16px-vh max-w-40px-mvw bottom-26px-vh">
                      <img
                        src="/images/category/arrow.png"
                        alt="arrow"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                {selectedTeamMember !== item.id && (
                  <div className="font-poppins pt-12px-mvw md:pt-0 font-medium text-[#2B2A29] text-18px-mvw md:text-32px-vw">
                    {item.name}
                  </div>
                )}

                {selectedTeamMember === item.id && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className="p-12px-mvw rounded-b-[5.33vw] text-[#2B2A29] text-opacity-80 text-16px-mvw md:text-16px-vw font-poppins border-l border-b border-r border-[#707070] border-opacity-40"
                  >
                    <h3 className="font-poppins font-medium text-[#2B2A29] text-18px-mvw md:text-32px-vw">
                      {item.name}
                    </h3>
                    <div
                      className=" mt-8px-mvw"
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                    />
                  </motion.div>
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedMember ? "selected" : "grid"}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className={`w-full grid gap-y-16px-mvw md:gap-y-0 ${
                selectedMember ? "md:grid-cols-1" : "md:grid-cols-3"
              } grid-cols-1 gap-x-16px-vw`}
            >
              {/* Render the grid only if no member is selected */}
              {!selectedMember &&
                TeamData.map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="flex flex-col  gap-y-20px-vw "
                  >
                    <motion.div
                      className="border cursor-pointer relative hover:bg-white border-solid border-[#707070] border-opacity-40 rounded-[1.04vw]"
                      onClick={() => handleMemberClick(item)}
                      whileHover={{ scale: 1.02 }}
                    >
                      <img
                        src={item.img}
                        alt="doctor"
                        className="w-full h-full object-cover"
                      />

                      <motion.div className="absolute bottom-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-500">
                        <img
                          src="/images/category/active_arrow.svg"
                          alt="arrow"
                          loading="lazy"
                        />
                        <div className="absolute right-16px-vh bottom-36px-vh">
                          <img
                            src="/images/category/arrow.png"
                            alt="arrow"
                            loading="lazy"
                          />
                        </div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="font-poppins md:ml-0 font-medium text-[#2B2A29] text-18px-mvw md:text-32px-vw">
                      {item.name}
                    </motion.div>
                  </motion.div>
                ))}

              {/* Render the selected member details */}
              {selectedMember && (
                <motion.div
                  key="selected-member"
                  initial={{ x: "100%", opacity: 0 }}
                  animate={{ x: "0%", opacity: 1 }}
                  exit={{ x: "100%", opacity: 0 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  className="border border-[#707070] border-opacity-40 w-full grid grid-cols-[30%_70%]  rounded-[1.04vw] relative"
                >
                  <div className="cursor-pointer relative hover:bg-white border-opacity-40 rounded-[1.5vw]">
                    <img
                      src={selectedMember.img}
                      alt="doctor"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <button
                    className="absolute top-[0.25vw] right-24px-vw text-[#2B2A29] opacity-80 text-[2.5vw] z-10"
                    onClick={handleClose}
                  >
                    &times;
                  </button>
                  <div className="px-40px-vw flex flex-col items-start justify-start pb-15px-vw font-poppins text-[#2B2A29] text-20px-vw overflow-y-auto max-h-[31.41vw] ">
                    <h2 className="font-poppins py-20px-vw font-medium text-[#2B2A29] text-18px-mvw md:text-32px-vw">
                      {selectedMember.name}
                    </h2>
                    <p
                      className="text-[#2B2A29] text-20px-vw md:pr-20px-vw text-opacity-80"
                      dangerouslySetInnerHTML={{ __html: selectedMember.desc }}
                    />
                  </div>
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};

export default OurTeam;
