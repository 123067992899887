import React from 'react';

const ParaText = ({ paratext, CustomCss }) => {
  return (
    <p
      className={`text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw opacity-80 ${CustomCss}`}
    >
      {paratext}
    </p>
  );
};

export default ParaText;
