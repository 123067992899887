import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./search.scss";
import { Link } from "react-router-dom";
import Aos from "aos";
import { search } from "../../Data/Search";
const listingData = [
  {
    item: {
      id: "01",
      name: "Steam Sterilizers",
      img: "/images/search/steam.png",
      link: "/products/retina-glaucoma/oct",
    },
  },
  {
    item: {
      id: "02",
      name: "indicators",
      img: "/images/search/indicator.png",
      link: "/products/retina-glaucoma/perimeters",
    },
  },
  {
    item: {
      id: "03",
      name: "Packaging Material",
      img: "/images/search/packaging_material.png",
      link: "/products/retina-glaucoma/tonometer",
    },
  },
  {
    item: {
      id: "04",
      name: "Pachymeter",
      img: "/images/rectina/Pachette 4.png",
      link: "/products/retina-glaucoma/pachymeter",
    },
  },
  {
    item: {
      id: "05",
      name: "ERG",
      img: "/images/category/5.png",
      link: "/products/retina-glaucoma/erg",
    },
  },
  {
    item: {
      id: "06",
      name: "B-Scan",
      img: "/images/category/6.png",
      link: "/products/retina-glaucoma/b-scan",
    },
  },
];

const Search = () => {
  const [products, setProducts] = useState(listingData);
  const [query, setQuery] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);

  const ListingCards = products?.map(({ item }, index) => (
    <Link key={index} to={item.link} className="">
      <div
        className="product_col h-full md:max-h-full md:pb-25px-vw  w-full relative hover:border-none rounded-[5.33vw] md:rounded-[1.04vw] bg-white/20  backdrop-blur-[50px]  md:bg-opacity-100 md:bg-none flex flex-col items-start justify-between space-y-12px-vh text-18px-mvw transition-all duration-300 cursor-pointer hover:bg-white"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="40"
      >
        <div className="flex w-full items-start justify-between">
          <div>
            <div className="text_nowrap">{item.name}</div>
          </div>
          <div className="md:max-w-[10vw] max-w-[40%]">
            <img
              src={item.logo}
              alt=""
              className="w-full h-full object-cover "
            />
          </div>
        </div>

        <div className="md:max-h-[25.04vw] w-full flex items-center justify-center">
          <img
            src={item.img}
            alt=""
            className="object-contain w-full h-full "
          />
        </div>

        {/* Arrow hidden initially, visible on hover */}
        <div className="absolute bottom-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-300">
          <img
            src="/images/category/active_arrow.svg"
            alt="arrow"
            loading="lazy"
          />
          <div className="absolute right-17px-vh bottom-17px-vh">
            <img
              src="/images/category/arrow.png"
              alt="arrow"
              loading="lazy"
              className="md:max-w-28px-vw h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </Link>
  ));

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const query = e.target.value;
    console.log({ query });
    setQuery(query);
    if (query == "") {
      setProducts(listingData);
      return;
    }
   

    const result = search(query);

    setProducts(result);
  };

  return (
    <>
      <section
        className="search_sec1 md:px-[7.3vw] px-16px-mvw"
        data-aos="fade"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <form>
          <div className="input_wrapper">
            <input
              className={`input_field w-full`}
              type="text"
              name="search"
              placeholder="Search..."
              onChange={handleChange}
            />
            <div className="icon_wrapper">
              <FiSearch className="search_icon" />
            </div>
          </div>
          <p className="result_text">
            {query && products.length == 0 ? "No data found" : ""}
            {query.length > 0 && products.length > 0 ? `Result for ${query}` : ""}
          </p>
        </form>
      </section>

      <section
        className="search_sec2 md:mt-40px-vw mt-40px-mvw px-16px-mvw md:px-[7.3vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="product_row md:pt-40px-vw pt-40px-mvw w-full grid lg:grid-cols-5 md:grid-cols-3 justify-center grid-cols-1 md:gap-x-17px-vw md:gap-y-34px-vw md:pb-[7vw] pb-[21.33vw] gap-20px-mvw">
          {ListingCards}
        </div>
      </section>
    </>
  );
};

export default Search;
