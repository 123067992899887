import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import VisionCard from "../common/VisionCard";
import Aos from "aos";

const Vision = () => {
  const visondata = [
    {
      id: 1,
      img: "/images/about/Quality.svg",
      title: "Quality",
      desc: "We are committed to importing and delivering the highest quality ophthalmic devices from reputable international suppliers. Our goal is to ensure that every product we provide meets the most stringent standards of excellence, contributing to superior patient care and outcomes.",
    },
    {
      id: 2,
      img: "/images/about/Service.svg",
      title: "Service",
      desc: "Our vision includes being the trusted service partner for healthcare professionals throughout their careers. We aim to offer unparalleled support and maintenance, backed by well-trained staff and professionals who are dedicated to staying updated with the latest advancements in technology.",
    },
    {
      id: 3,
      img: "/images/about/Accessibility.svg",
      title: "Accessibility",
      desc: "We strive to make advanced ophthalmic medical devices accessible across India, bridging market gaps and ensuring that healthcare professionals, regardless of their location, have the tools they need to provide exceptional eye care.",
    },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <main
      className="w-full md:px-[7.29vw]  md:py-[6.25vw]"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex md:flex-row flex-col items-start md:gap-x-[7.5vw]">
        <SectionHeading title={"VISION"} customCss={"whitespace-nowrap"} />
        <ParaText
          paratext={
            "At My Healthskape Medicals Private Limited, our vision is to be the foremost provider of innovative and high-quality ophthalmic solutions in India. We aspire to set the benchmark for excellence in eye care by advancing technology and ensuring accessibility, driven by our core pillars of Quality, Service, and Accessibility."
          } 
          CustomCss={"md:mt-0 mt-20px-mvw"} 
        />
      </div>
      <div className="grid w-full h-full py-32px-mvw gap-y-20px-mvw md:gap-y-0 md:grid-cols-3 md:py-50px-vw grid-cols-1 gap-16px-vw">
        <VisionCard data={visondata} />
      </div>
      <div className="w-full hidden md:block">
        <ParaText
          paratext={
            "We envision a future where every healthcare professional is empowered with the best tools and support to deliver unparalleled eye care, ultimately enhancing the vision and lives of people across the nation."
          }
        />
      </div>
    </main>
  );
};

export default Vision;
