import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import Aos from "aos";

// Category data
const productCategories = [
  {
    id: 1,
    name: "Retina & Glaucoma",
    link: "products/retina-glaucoma",
    machines: [
      {
        id: 1,
        name: "OCT",
        image: "/images/rectina/FC130-New.png",
        link: "products/retina-glaucoma/oct",
      },
      {
        id: 2,
        name: "Perimeters",
        image: "/images/category/2.png",
        link: "products/retina-glaucoma/perimeters",
      },
      {
        id: 3,
        name: "Tonometer",
        image: "/images/category/2.png",
        link: "products/retina-glaucoma/tonometer",
      },
      {
        id: 4,
        name: "Pachymeter",
        image: "/images/category/3.png",
        link: "products/retina-glaucoma/pachymeter",
      },
      {
        id: 5,
        name: "ERG",
        image: "/images/category/4.png",
        link: "products/retina-glaucoma/erg",
      },
      {
        id: 6,
        name: "B-Scan",
        image: "/images/category/5.png",
        link: "products/retina-glaucoma/b-scan",
      },
      {
        id: 7,
        name: "Fundus Camera",
        image: "/images/category/6.png",
        link: "products/retina-glaucoma/fundus-camera",
      },
    ],
  },
  {
    id: 2,
    name: "Cornea & Refractive",
    link: "products/cornea-refractive",
    machines: [
      {
        id: 1,
        name: "Topographer",
        image: "/images/cornea/ant2.png",
        link: "products/cornea-refractive/topographer",
      },
      {
        id: 2,
        name: "Lasik Laser System",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/lasik-laser-system",
      },
      {
        id: 3,
        name: "Microkeratome",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/microkeratome",
      },

      {
        id: 4,
        name: "Pachymeter",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/pachymeter",
      },
      {
        id: 5,
        name: "AS-OCT",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/as-cot",
      },
      {
        id: 6,
        name: "Keratoplasty",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/keratoplasty",
      },
      {
        id: 7,
        name: "Dry Eye",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/dry-ice",
      },
      {
        id: 8,
        name: "Specular Microscope",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/specular-microscope",
      },
      {
        id: 9,
        name: "UBM",
        image: "/images/home/FC130-New.png",
        link: "products/cornea-refractive/ubm",
      },
    ],
  },
  {
    id: 3,
    name: "Cataract",
    link: "products/cataract",
    machines: [
      {
        id: 1,
        name: "Ultrasound A-Scan",
        image: "/images/cataract/oae@2x.png",
        link: "products/cataract/ultrasound-a-scan",
      },
      {
        id: 2,
        name: "Optical Biometer",
        image: "/images/home/FC130-New.png",
        link: "products/cataract/optical-biometer",
      },
      {
        id: 3,
        name: "Wavefront Aberrometer",
        image: "/images/home/FC130-New.png",
        link: "products/cataract/wavefront-aberrometer",
      },
      {
        id: 4,
        name: "Selective Laser Capsulotomy",
        image: "/images/home/FC130-New.png",
        link: "products/cataract/selective-laser-capsulotomy",
      },
      {
        id: 5,
        name: "Surgical Blades",
        image: "/images/home/FC130-New.png",
        link: "products/cataract/surgical-blades",
      },
      {
        id: 6,
        name: "OT Table & Chair",
        image: "/images/home/FC130-New.png",
        link: "products/cataract/OTTable-Chair",
      },
    ],
  },
  {
    id: 4,
    name: "Primary Diagnostics",
    link: "products/primary-diagnostics",
    machines: [
      {
        id: 1,
        name: "Slit Lamp",
        image: "/images/primary/SL9800-ELITE.png",
        link: "products/primary-diagonostics/slit-lamp",
      },
      {
        id: 2,
        name: "Lensmeter",
        image: "/images/home/FC130-New.png",
        link: "products/primary-diagonostics/lensmeter",
      },
    ],
  },
  {
    id: 5,
    name: "Sterilization & Indicators",
    link: "products/sterilization-indicators",
    machines: [
      {
        id: 1,
        name: "Steam Sterilizers",
        image: "/images/sterilization/HS-2522BL.png",
        link: "products/sterilization-indicators/steam-sterilizers",
      },
      {
        id: 2,
        name: "Sterilization Indicators",
        image: "/images/home/FC130-New.png",
        link: "products/sterilization-indicators/indicators",
      },
      {
        id: 3,
        name: "Packaging Material",
        image: "/images/home/FC130-New.png",
        link: "products/sterilization-indicators/packaging-material",
      },
    ],
  },
];

const ProductCategories = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Active category
  const [hoveredMachineIndex, setHoveredMachineIndex] = useState(null); // To track hover
  const [isExpanded, setIsExpanded] = useState(false);

  const activeCategory = productCategories[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div
      className="w-full pt-[9.21vw] py-[4.5vw] safari-fix  relative md:px-[6.77vw] px-4"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      {/* Header Section */}
      <div className="w-full h-full safari-gap-fix grid md:grid-cols-[auto_1fr_auto] grid-cols-1 gap-y-20px-mvw place-items-center ">
        <div className="justify-self-start md:w-1/2 ">
          <SectionHeading
            title={"PRODUCT CATEGORIES"}
            customCss={"mt-60px-mvw md:mt-0"}
          />
        </div>
        <div className="w-full  md:max-w-[90%] max-w-full justify-self-start ">
          <ParaText
            paratext={
              "Our product portfolio encompasses a wide range of the latest and most advanced ophthalmic products, meticulously designed to cater to every aspect of eye care."
            }
            className={"md:text-ellipsis md:line-clamp-2"}
          />
        </div>
        <div className="md:justify-self-end justify-self-start mt-8px-mvw md:mt-0">
          {/* Link View All button to the active category's page */}
          <Link to={activeCategory.link}>
            <Button title={"View All"} />
          </Link>
        </div>
      </div>

      {/* Category Tabs */}
      <div
        className="w-full hidden rounded-[4vw] safari-tab-fix    bg-white bg-opacity-30 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-50px-vw z-10 mt-74px-vh gap-y-40px-vh p-10px-vw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {productCategories.map((item, index) => (
          <div
            key={item.id}
            className={`relative cursor-pointer ${
              activeIndex === index
                ? "opacity-100 font-bold backdrop-blur-[26px]   md:rounded-[3vw] rounded-[8vw] px-20px-vw py-12px-vh"
                : "opacity-40 font-medium"
            }`}
            onClick={() => setActiveIndex(index)}
          >
            <div
              className={`relative ${
                activeIndex === index
                  ? "relative flex items-center justify-center px-32px-vw py-10px-vw text-28px-vw font-semibold text-[#2B2A29] rounded-[30px] bg-gradient-to-r from-[rgba(81,154,127,0.3)] via-[rgba(81,154,127,0.6)] to-[rgba(81,154,127,0.3)] backdrop-blur-[26px] shadow-[0px_-15px_40px_rgba(81,154,127,0.7),0px_10px_20px_rgba(0,0,0,0.15)] overflow-hidden "
                  : ""
              } flex items-center justify-center `}
            >
              <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                {item.name}
              </span>
              <div className="absolute top-0 left-0 w-full h-full rounded-[inherit] bg-gradient-to-b from-transparent to-[rgba(255,255,255,0.25)] pointer-events-none"></div>
            </div>
          </div>
        ))}
      </div>

      {/* mobile dropdown  */}
      <div className="md:hidden block">
        <div className="grid grid-cols-1  pt-36px-mvw relative">
          <div
            className="  py-20px-mvw inline-flex justify-between  items-center rounded-[5.33vw] "
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="relative w-full flex items-center  justify-between md:px-32px-vw px-25px-mvw md:py-10px-vw py-20px-mvw md:text-28px-vw text-18px-mvw font-semibold text-[#2B2A29] rounded-[30px] bg-gradient-to-r from-[rgba(81,154,127,0.3)] via-[rgba(81,154,127,0.6)] to-[rgba(81,154,127,0.3)] backdrop-blur-[26px] shadow-[0px_-15px_40px_rgba(81,154,127,0.7),0px_10px_20px_rgba(0,0,0,0.15)] overflow-hidden">
              <div className="absolute top-0 left-0 w-full  h-full rounded-[inherit] bg-gradient-to-b from-transparent to-[rgba(255,255,255,0.25)] pointer-events-none"></div>
              <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                {productCategories[activeIndex]?.name || "Select Category"}
              </span>
              <div className="text-18px-mvw md:mr-40px-mvw text-[#2B2A29]">
                {isExpanded ? <HiChevronUp /> : <HiChevronDown />}
              </div>
            </div>
          </div>

          <AnimatePresence>
            {isExpanded && (
              <motion.div
                className="absolute !bg-white top-full left-0 w-full text-opacity-80 rounded-[8vw] text-[#2B2A29] font-poppins font-medium text-16px-mvw p-25px-mvw mt-[-3.93vw] !z-20"
                initial={{ opacity: 0, height: 0 }} // Start state
                animate={{ opacity: 1, height: "auto" }} // Animate to these values
                exit={{ opacity: 0, height: 0 }} // Exit animation
                transition={{ duration: 0.3, ease: "easeInOut" }} // Duration and easing
              >
                {productCategories.map(
                  (category, index) =>
                    index !== activeIndex && (
                      <div key={category.id}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveIndex(index);
                            setIsExpanded(false);
                          }}
                        >
                          {category.name}
                        </div>
                        {index !== productCategories.length - 1 && (
                          <div className="w-full mx-auto border-[1px] border-[#2B2A29] opacity-10 my-13px-mvw"></div>
                        )}
                      </div>
                    )
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Content: Left (Machine Names) and Right (Image) */}
      <div
        className="grid md:grid-cols-2 grid-cols-1  md:mt-0 w-full gap-y-60px-vh md:gap-y-0 py-76px-vw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {/* Left: Machine Names */}
        <div className="flex flex-col gap-y-28px-vh md:pr-[15.15vw]">
          {activeCategory.machines.map((machine, index) => (
            <Link to={machine.link} key={index}>
              <div
                className="flex items-center justify-between md:mb-16px-vh pb-[4.67vw] md:pb-28px-vw cursor-pointer relative"
                onMouseEnter={() => setHoveredMachineIndex(index)}
                onMouseLeave={() => setHoveredMachineIndex(null)}
              >
                {/* Machine Name */}
                <div className="md:text-32px-vw text-18px-mvw text-[#2B2A29] font-bold font-raleway opacity-40 md:opacity-60 md:font-poppins md:font-semibold">
                  <span className="md:hidden">0{machine.id}. </span>
                  {machine.name}
                </div>

                <div className="absolute left-0 bottom-0 w-full border-b-2 opacity-10 border-[#2B2A29]" />

                {/* Green Arrow on Hover */}
                <div
                  className={`absolute right-0 top-[30%] transform -translate-y-1/2 transition-opacity duration-300 ease-in-out ${
                    hoveredMachineIndex === index ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <div className="md:w-44px-vw w-22px-mvw">
                    <img
                      src="/icon/blue_arrow.png"
                      alt="green_arrow"
                      loading="lazy"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Right: Only One Image for the Active Tab */}
        <div className="flex justify-center items-center h-full md:max-w-[50vw] md:py-0">
          <Link to={activeCategory.link}>
            <img
              src={activeCategory.machines[0]?.image} // Show the first image of the active category
              alt={activeCategory.machines[0]?.name}
              className="w-full h-auto md:w-auto object-contain "
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;
