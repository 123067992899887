import React, { useEffect } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import QaSection from "../../components/Faqs/QaSection";

const Faqs = () => {
  const faqs = {
    heading: "PRODUCTS",
    items: [
      {
        question:
          "Lorem Ipsum is simply dummy text of the printing and typesetting?",
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      {
        question:
          "Lorem Ipsum is simply dummy text of the printing and typesetting?",
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      {
        question:
          "Lorem Ipsum is simply dummy text of the printing and typesetting?",
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      {
        question:
          "Lorem Ipsum is simply dummy text of the printing and typesetting?",
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | FAQs"}
        centerHeading={"Frequently Asked Questions"}
      />

      <QaSection heading={"PRODUCTS"} data={faqs} />
      <QaSection heading={"ORDERS"} data={faqs} />
      <QaSection heading={"SHIPPING"} data={faqs} />
      <QaSection
        heading={"PAYMENT"}
        data={faqs}
        customCss={"md:pb-[4vw] pb-60px-mvw"}
      />
    </section>
  );
};

export default Faqs;
