import Fuse from "fuse.js";

export const data = [
  // Retina & Glaucoma
  // 1. OCT
  {
    name: "OCT",
    img: "/images/category/1.png",
    link: "/products/retina-glaucoma/oct",
  },
  {
    name: "REVO FC 130",
    img: "/images/rectina/FC130-New.png",
    link: "/products/retina-glaucoma/oct",
  },
  {
    name: "REVO FC",
    img: "/images/rectina/Revo-FC.png",
    link: "/products/retina-glaucoma/oct",
  },
  {
    name: "REVO 60",
    img: "/images/rectina/revo-fc60.png",
    link: "/products/retina-glaucoma/oct",
  },

  // Retina & Glaucoma
  // 2. Perimeters
  {
    name: "Perimeters",
    img: "/images/category/2.png",
    link: "/products/retina-glaucoma/perimeters",
  },
  {
    name: "PTS 925W",
    img: "/images/rectina/20.-PTS-925W-(1).png",
    link: "/products/retina-glaucoma/perimeters",
  },
  {
    name: "PTS 2000",
    img: "/images/rectina/pts-2000.png",
    link: "/products/retina-glaucoma/perimeters",
  },

  // Retina & Glaucoma
  // 3. Tonometer

  {
    name: "Tonometer",
    img: "/images/category/3.png",
    link: "/products/retina-glaucoma/tonometer",
  },
  {
    name: "A900 F900 Z800",
    img: "/images/rectina/tonoimg.png",
    link: "/products/retina-glaucoma/tonometer",
  },
  {
    name: "FT-1000",
    img: "/images/rectina/Fttono.png",
    link: "/products/retina-glaucoma/tonometer",
  },

  // Retina & Glaucoma
  // 4. Pachymeter
  {
    name: "Pachymeter",
    img: "/images/rectina/Pachette 4.png",
    link: "/products/retina-glaucoma/pachymeter",
  },
  {
    name: "Pachette 4",
    img: "/images/rectina/Pachette%204.png",
    link: "/products/retina-glaucoma/pachymeter",
  },
  {
    name: "Pachmate 2",
    img: "/images/rectina/Pachmate-2.png",
    link: "/products/retina-glaucoma/pachymeter",
  },

  // Retina & Glaucoma
  // 5. ERG
  {
    name: "ERG",
    img: "/images/category/5.png",
    link: "/products/retina-glaucoma/erg",
  },
  {
    name: "RETEVAL",
    img: "/images/rectina/RETeval-full-side-view_pupil.png",
    link: "/products/retina-glaucoma/erg",
  },
  {
    name: "EP-1000 Multifocal",
    img: "/images/rectina/EP-1000Mf.png",
    link: "/products/retina-glaucoma/erg",
  },

  // Retina & Glaucoma
  // 6. B-Scan
  {
    name: "B-Scan",
    img: "/images/category/6.png",
    link: "/products/retina-glaucoma/b-scan",
  },
  {
    name: "SCANMATE FLEX",
    img: "/images/rectina/Scanmate-FLEX.png",
    link: "/products/retina-glaucoma/scanmate-flex",
  },
  {
    name: "UD-800",
    img: "/images/rectina/UD-800_Side_0002.png",
    link: "/products/retina-glaucoma/ud-800",
  },

  // Retina & Glaucoma
  // 7.Fundus Camera
  {
    name: "Fundus Camera",
    img: "/images/category/7.png",
    link: "/products/retina-glaucoma/fundus-camera",
  },

  // Cornea & Refractive
  // 1. Topographer
  {
    name: "Topographer",
    img: "/images/cornea/cor-1.png",
    link: "/products/cornea-refractive/topographer",
  },
  {
    name: "ANTARES + Corneal Topographer",
    img: "/images/cornea/ant1.png",
    link: "/products/cornea-refractive/topographer",
  },
  {
    name: "SIRIUS +",
    img: "/images/cornea/ant2.png",
    link: "/products/cornea-refractive/topographer",
  },
  {
    name: "TMS-4N",
    img: "/images/cornea/ant3.png",
    link: "/products/cornea-refractive/topographer",
  },

  // Cornea & Refractive
  // 2.Lasik Laser System
  {
    name: "Lasik Laser System",
    img: "/images/cornea/cor-2.png",
    link: "/products/cornea-refractive/lasik-laser-system",
  },
  // {
  //   name: "Wavelight Refractive Suite EX 500 + FS 200",
  //   img: "/images/refractive/lasik.png",
  //   link: "/products/cornea-refractive/lasik-laser-system",
  // },
  // {
  //   name: "Wavelight Topolyzer Vario",
  //   img: "/images/refractive/Topolyzer-Vario@2x.png",
  //   link: "/products/cornea-refractive/lasik-laser-system",
  // },

  // Cornea & Refractive
  // 3.Microkeratome
  {
    name: "Microkeratome",
    img: "/images/cornea/mor1.png",
    link: "/products/cornea-refractive/microkeratome",
  },

  // Cornea & Refractive
  // 4.Pachymeter
  {
    name: "Pachymeter",
    img: "/images/cornea/cor-4.png",
    link: "/products/cornea-refractive/pachymeter",
  },
  {
    name: "PACHETTE 4",
    img: "/images/rectina/Pachette%204.png",
    link: "/products/cornea-refractive/pachymeter",
  },
  {
    name: "PACHMATE 2",
    img: "/images/rectina/Pachmate-2.png",
    link: "/products/cornea-refractive/pachymeter",
  },

  // Cornea & Refractive
  // 5. AS-OCT
  {
    name: "AS-OCT",
    img: "/images/cornea/cor-5.png",
    link: "/products/cornea-refractive/as-oct",
  },
  {
    name: "MS-39",
    img: "/images/cornea/asimg.png",
    link: "/products/cornea-refractive/as-oct",
  },
  {
    name: "CASIA2",
    img: "/images/cornea/asimg1.png",
    link: "/products/cornea-refractive/as-oct",
  },

  // Cornea & Refractive
  // 6. Keratoplasty
  {
    name: "Keratoplasty",
    img: "/images/cornea/cor-6.png",
    link: "/products/cornea-refractive/keratoplasty",
  },
  {
    name: "The adjustable vacuum Trephine & Punch for PK & DALK procedures",
    img: "/images/cornea/kerimg.png",
    link: "/products/cornea-refractive/keratoplasty",
  },
  {
    name: "Moria Evo 3e console with DSAEK + ALTK set",
    img: "/images/cornea/kerimg1.png",
    link: "/products/cornea-refractive/keratoplasty",
  },

  // Cornea & Refractive
  // 7. Dry Eye
  {
    name: "Dry Eye",
    img: "/images/cornea/eye.png",
    link: "/products/cornea-refractive/dry-ice",
  },
  {
    name: "IDRA",
    img: "/images/cornea/dry.png",
    link: "/products/cornea-refractive/dry-ice",
  },
  {
    name: "ACTIVA",
    img: "/images/cornea/active.png",
    link: "/products/cornea-refractive/dry-ice",
  },
  {
    name: "TEARCHECK",
    img: "/images/cornea/tear.png",
    link: "/products/cornea-refractive/dry-ice",
  },
  {
    name: "E-EYE",
    img: "/images/cornea/eye.png",
    link: "/products/cornea-refractive/dry-ice",
  },

  // Cornea & Refractive
  //  8. Specular Microscope
  {
    name: "Specular Microscope",
    img: "/images/cornea/cor-8.png",
    link: "/products/cornea-refractive/specular-microscope",
  },

  // Cornea & Refractive
  //  9. UBM
  {
    name: "UBM",
    img: "/images/cornea/cor-9.png",
    link: "/products/cornea-refractive/ubm",
  },
  {
    name: "SCANMATE FLEX",
    img: "/images/rectina/Scanmate-FLEX.png",
    link: "/products/cornea-refractive/scanmate-flex",
  },
  {
    name: "UD-800",
    img: "/images/rectina/UD-800_Side_0002.png",
    link: "/products/cornea-refractive/ud-800",
  },

  // Cataract
  // 1.Ultrasound A-Scan
  {
    name: "Ultrasound A-Scan",
    img: "/images/cataract/cat-6.png",
    link: "/products/cataract/ultrasound-a-scan",
  },
  {
    name: "AL-4000",
    img: "/images/cataract/al-4000@2x.png",
    link: "/products/cataract/ultrasound-a-scan",
  },
  {
    name: "Scanmate A DGH 600",
    img: "/images/cataract/scanmate-a@2x.png",
    link: "/products/cataract/ultrasound-a-scan",
  },

  // Cataract
  // 2.Optical Biometer
  {
    name: "Optical Biometer",
    img: "/images/cataract/cat-5.png",
    link: "/products/cataract/optical-biometer",
  },

  // Cataract
  // 3.Wavefront Aberrometer
  {
    name: "Wavefront Aberrometer",
    img: "/images/cataract/cat-4.png",
    link: "/products/cataract/wavefront-aberrometer",
  },

  // Cataract
  // 4.Selective Laser Capsulotomy
  {
    name: "Selective Laser Capsulotomy",
    img: "/images/cataract/pavel.png",
    link: "/products/cataract/selective-laser-capsulotomy",
  },

  // Cataract
  // 5.Surgical Blades
  {
    name: "Surgical Blades",
    img: "/images/cataract/cat-2.png",
    link: "/products/cataract/surgical-blades",
  },

  // Cataract
  // 6.OT Table & Chair
  {
    name: "OT Table & Chair",
    img: "/images/cataract/cat-1.png",
    link: "/products/cataract/OTTable-Chair",
  },
  {
    name: "500 XLE",
    img: "/images/cataract/500-XLE.png",
    link: "/products/cataract/OTTable-Chair",
  },
  {
    name: "SurgiLine",
    img: "/images/cataract/SurgiLine.png",
    link: "/products/cataract/OTTable-Chair",
  },

  // Primary Diagnostics
  // 1.Slit-Lamp
  {
    name: "Slit-Lamp",
    img: "/images/primary/SL9800-ELITE.png",
    link: "/products/primary-diagonostics/slit-lamp",
  },
  {
    name: "SL9900/ SL9800",
    img: "/images/primary/SL9800.png",
    link: "/products/primary-diagonostics/slit-lamp",
  },
  {
    name: "SL9800 ELITE",
    img: "/images/primary/SL9800-ELITE.png",
    link: "/products/primary-diagonostics/slit-lamp",
  },

  // Primary Diagnostics
  // 2.Lensmeter
  {
    name: "Lensmeter",
    img: "/images/primary/pri-2.png",
    link: "/products/primary-diagonostics/lensmeter",
  },
  {
    name: "TL-7000",
    img: "/images/primary/TL-7000.png",
    link: "/products/primary-diagonostics/lensmeter",
  },
  {
    name: "TL-6000",
    img: "/images/primary/TL-7000.png",
    link: "/products/primary-diagonostics/lensmeter",
  },

  // STERILIZATION & INDICATORS PRODUCTS
  //  1.Steam Sterilizers
  {
    name: "Steam Sterilizers",
    img: "/images/sterilization/str-1.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
  },
  {
    name: "HS-60SL",
    img: "/images/sterilization/HS-60SL.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
  },
  {
    name: "HS-2522BL",
    img: "/images/sterilization/HS-2522BL.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
  },
  {
    name: "HS-2522SD",
    img: "/images/sterilization/HS-2522SD.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
  },
  {
    name: "HS-1607SD",
    img: "/images/sterilization/HS-1607SD.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
  },

  // STERILIZATION & INDICATORS PRODUCTS
  //  2. Indicator
  {
    name: "Indicator",
    img: "/images/sterilization/image002.png",
    link: "/products/sterilization-indicators/indicators",
  },

  // STERILIZATION & INDICATORS PRODUCTS
  // 3.Packaging Material
  {
    name: "Packaging Material",
    img: "/images/sterilization/str-3.png",
    link: "/products/sterilization-indicators/packaging-material",
  },
  {
    name: "Steriking Pouches & Roll",
    img: "/images/sterilization/img_006.png",
    link: "/products/sterilization-indicators/packaging-material",
  },
  {
    name: "Rotosealer",
    img: "/images/sterilization/img_007.png",
    link: "/products/sterilization-indicators/packaging-material",
  },
];

const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.2,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name"],
};
export const search = (query) => {
  const fuse = new Fuse(data, fuseOptions);

  // Change the pattern
  const searchPattern = "";

  const result = fuse.search(query);
  console.log({ result });
  return result;
};
