import Aos from "aos";
import React, { useEffect } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";

const LasikLaserSystem = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full relative">
      <CategoryTopSection
        breadcrumb={
          "Home | Products | Cornea & Refractive | Lasik Laser System"
        }
        centerHeading={"Lasik Laser System"}
      />

      <section
        className="grid md:grid-cols-2 gap-x-[8vw] font-poppins md:my-100px-vw my-60px-mvw md:px-[7.29vw]  px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div
          className="flex  gap-y-16px-mvw md:gap-y-0 items-start flex-col justify-start 
        "
        >
          <div className="md:max-w-full max-w-[50%]">
            <img src={"/images/refractive/logo.png"} alt="logo.png" />
          </div>

          <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw  md:max-w-[80%] md:pt-40px-vw">
          Wavelight Refractive Suite EX 500 + FS 200
          </div>
          <div className="font-poppins h-[3vw] font-regular md:text-20px-vw  text-16px-mvw md:pt-32px-vw opacity-80 text-[#2B2A29] md:max-w-[80%]">
            Achieve outstanding visual outcomes with our integrated Wavelight
            Technology
          </div>
          <div className="flex items-start justify-start">
            <img src="/images/refractive/lasik.png" alt="refractive" />
          </div>
        </div>
        <div
          className="md:mt-[6vw] flex items-start flex-col justify-start gap-y-40px-vw 
        "
        >
          <div className="font-semibold  md:text-48px-vw text-24px-mvw md:max-w-[80%]">
            Wavelight Topolyzer Vario
          </div>
          <div className="font-poppins h-[3vw] font-regular md:text-20px-vw opacity-80 text-16px-mvw text-[#2B2A29] md:max-w-[80%]">
            Precision measurements for personalized treatment
          </div>
          <div className="pt-[5vw] w-full h-full">
            <img
              src="/images/refractive/Topolyzer-Vario@2x.png"
              alt="refractive"
              className="w-auto md:max-h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LasikLaserSystem;
