import { BiChevronUp } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Aos from "aos";
import { img } from "framer-motion/client";

const QaSection = ({ heading, data, customCss }) => {
  // Set the initial state based on the heading
  const initialIndex = heading === "PRODUCTS" ? 0 : null;
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div
      className={`w-full md:px-[7.29vw] px-16px-mvw pt-60px-mvw  md:pt-[6vw] ${customCss || ""}`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <h2 className="font-raleway font-bold md:text-40px-vw text-24px-mvw uppercase text-[#1C1C1C] md:mb-20px-vw">
        {heading}
      </h2>
      {data.items.map((item, index) => (
        <div
          key={index}
          className={`border-b border-[#1C1C1C] border-opacity-20 ${
            index === data.items.length - 1 ? "border-b-0" : ""
          }`}
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full flex items-center justify-between text-left py-40px-vw focus:outline-none"
          >
            <span className="font-poppins font-medium text-[#1C1C1C] md:text-28px-vw  text-16px-mvw">
              {item.question}
            </span>

            <span className="text-4xl opacity-70">
              {activeIndex === index ? <img src="/icon/chevronup.svg" alt="upicon" className="md:w-[1.5vw]"/> :<img src="/icon/chevrondown.svg" className="md:w-[1.5vw]" alt="upicon"/> }
            </span>
          </button>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: activeIndex === index ? "auto" : 0,
              opacity: activeIndex === index ? 1 : 0,
            }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <p className="font-poppins font-regular text-[#1C1C1C] md:max-w-[95%] md:text-22px-vw text-12px-mvw opacity-80 pb-20px-mvw md:pb-40px-vw">
              {item.answer}
            </p>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default QaSection;
