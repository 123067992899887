import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import ImageSlider from "../../slider/ImageSlider";
import Aos from "aos";
import { microkeratome } from "../../../Data/CorneaRefractive";

const Microkeratome = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Microkeratome"}
        centerHeading={"Microkeratome"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-80px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {microkeratome.map((item) => (
          <div key={item.id} className="w-full">
            <div
              className="grid md:grid-cols-[40%_60%] md:px-[7.29vw] px-16px-mvw
             grid-cols-1 md:py-80px-vw"
            >
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full max-w-[40%]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0 ">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  KEY FEATURES:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-start gap-x-12px-mvw md:gap-x-20px-vw">
                    <div className="flex-shrink-0 bg-[#519A7F] md:mt-[1vw] mt-8px-mvw rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center  ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[7.29vw]  gap-x-16px-vw md:pt-[3vw] pt-60px-mvw px-16px-mvw   mx-auto md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {/* slider */}
            <h1 className="font-raleway text-center md:pt-[6vw] font-bold md:text-32px-vw pt-60px-mvw  text-24px-mvw text-[#2B2A29]">
              PRODUCT GALLERY
            </h1>

            <div className="pt-60px-mvw md:pt-0">
              <ImageSlider data={item.images} />
            </div>

            {item.video &&
              item.video.map((videoItem, index) => (
                <div
                  key={index}
                  className="w-full flex md:px-[7.29vw] px-16px-mvw flex-col items-center justify-center md:pt-[6.25vw]"
                > 
                 <h1 className="font-raleway text-center font-bold md:text-32px-vw pt-60px-mvw md:pt-0  text-24px-mvw text-[#2B2A29]">
                      IMAGE & VIDEO GALLERY
                    </h1>
                  <div className="md:mt-60px-vw flex flex-col items-start relative">
                    {/* Thumbnail */}
                   
                    {!isPlaying ? (
                      <>
                        <img
                          src={videoItem.videoSrc} // Thumbnail image source
                          alt="video thumbnail"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div
                          className="absolute cursor-pointer inset-0 flex items-center justify-center"
                          onClick={handlePlay} // Play the video on click
                        >
                          <img
                            src="/icon/playIcon.png" // Play icon
                            alt="play icon"
                            className="md:w-73px-vw"
                          />
                        </div>
                      </>
                    ) : (
                      // Video player
                      <iframe
                        className="md:max-w-[80vw] block"
                        width="700" // Increase the width here
                        height="450" // Adjust the height accordingly
                        src={`https://www.youtube.com/embed/${videoItem.videoUrl
                          .split("/")
                          .pop()}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}

                    <div className="md:pt-20px-vw pt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-32px-vw text-16px-mvw">
                      {videoItem.videoTitle}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Microkeratome;
