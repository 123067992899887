export const data = [
  <>
    Established in 1996, My Healthskape Medicals Private Limited has been a
    leader in sourcing ophthalmic surgical equipments and diagnostic devices
    from various reputable international suppliers specializing in the
    ophthalmic industry.
    <br />
    We focus on bringing the advanced eye care technology to the Indian market,
    ensuring that healthcare professionals have access to the best diagnostic
    equipment, refractive lasers, and surgical instruments. Our success is built
    on a foundation of well-trained professionals who provide exceptional
    service.
    <br />
    We pride ourselves on being service partners for a lifetime, committed to
    supporting healthcare professionals with the tools they need to deliver
    outstanding patient care.
    <br />
  </>,
];

export const sustainabilityData = [
  "At Healthskape, we are committed to protecting both your vision and the environment. Our eco-friendly practices, from reducing paper waste and conserving energy to choosing sustainable products, reflect our dedication to a greener future. By integrating sustainability into every aspect of our practice, we strive to minimize our environmental impact while continuing to provide exceptional eye care. Together, let’s create a healthier planet for future generations. ",
];

export const partnerslogomobile = [
  { id: 1, imgsrc: "/images/alliance/img3.png" },
  { id: 2, imgsrc: "/images/alliance/img1.png" },
  { id: 3, imgsrc: "/images/alliance/img2.png" },
  { id: 4, imgsrc: "/images/alliance/img4.png" },
  { id: 5, imgsrc: "/images/alliance/img5.png" },
  { id: 6, imgsrc: "/images/alliance/img6.png" },

  { id: 8, imgsrc: "/images/alliance/img11.png" },
  
  { id: 10, imgsrc: "/images/alliance/img16.png" },
  { id: 11, imgsrc: "/images/alliance/img8.png" },
  { id: 12, imgsrc: "/images/alliance/img9.png" },
  { id: 13, imgsrc: "/images/alliance/img10.png" },

  { id: 15, imgsrc: "/images/alliance/img13.png" },
 
];

export const testimonials = [
  {
    quote: "Revo 27 User ",
    desc: "Excellent OCT in the market. ●	Best speed ●	User friendly ●	High resolution OCT scans of the retina and anterior structures ●	Best service",
    imgsrc: "/images/home/DR SANJAY SAVANI.png",
    name: "DR SANJAY SAVANI",
    designation: "MBBS, M.S. Opthal",
    location: "SAVANI EYE HOSPITAL",
  },
  {
    quote:
      "It is my pleasure to comment that we as a team of Dhami Eye Care Hospital are highly pleased with the quality of service provided by My Healthskape. We sincerely appreciate your responsibilities and the way your team give us the maintenance services of our equipment since last 25 yrs. We recommended My Healthskape to all as a supplier and for maintenance service. The Engineers are well trained and the application persons are perfect with latest knowledge of the products and clinical applications needed. All these boys are available a telephone call when away. The breakdown of any machine is put to order immediately. There has never been a loss of period of work.",
    desc: "We have a 25 yrs. association with My Healthskape. We always feel like a family and friend with My Healthskape, never on terms of business.We wish all the best for all members of My Healthskape family. Undoubtly they are the best.",
    imgsrc: "/images/home/DR GS DHAMI.png",
    name: "DR GS DHAMI",
    designation: "MBBS, M.D (Ophth.) ,F.I.C.O (Japan)",
    location: "DHAMI EYE CARE HOSPITAL",
  },

  {
    quote:
      "I wanted to share that we've been using the MS-39 from cso and are really impressed with its performance and reliability. ",
    desc: "",
    imgsrc: "/images/home/GANESH.png",
    name: "Dr Sri Ganesh",
    designation:
      "MBBS, MS, DNB, DSC (Hon), FRCS (Gla), FAICO (Hon), FWRCS HOD Phaco & Refractive Services",
    location: "Nethradhama Super Speciality Eye Hospital, Bangalore ",
  },
  {
    quote:
      "I have used My Healthskape products from the inception of my clinic-Darshan Eye care and Surgical Centre -Chennai, in 2006, and am extremely satisfied with the organization, their products and the service support. I have their equipment in both the Out-patient service and the Operation theatre and these include, vision chart projectors, slit lamps, specular microscope, pachymeter, visual field analyzer, digital biometer, autorefractokeratometer, tomography, optical coherence tomography, sterilizers, packing rolls, etc.",

    desc: "A special word about the Photo-Video slit lamps that they provide from cso - I have three of these and find them extremely easy to use, cost effective and of excellent quality. The photographs are of great quality and have a proper indexing system that allows for easy archival and retrieval. The videos are also easy to take and are of good quality  I have a tertiary ocular surface clinic and manage complicated problems of the same. I find their Idra ocular surface analyzer easy to use and provides good quality information that is in line with clinical findings and the documentation is neat and easy to understand-both for the clinician and the patient. For patients with severe meibomian gland dysfunction which is refractory to medical treatment, their E-eye device is very useful and effective.As an Organization, My Healthskape is very consumer friendly and easily accessible - both from the local service support team and the administration in Mumbai. I have had no problems at all with them during my long association and am happy to recommend them and their products to my friends.",
    imgsrc: "/images/home/DR SRINIVAS RAO.png",
    name: "DR SRINIVAS RAO",
    designation: "DO, DNB ,FRCSEd Cataract Corneal & Refractive Surgeon",
    location: "DARSHAN EYE CLINIC",
  },
  {
    quote:
      "We are thrilled to share my exceptional experience with the Revo FC 130 OCT angiography machine. The image quality it captures is truly outstanding, providing unparalleled clarity and detail that are crucial for making precise diagnoses in my daily practice. The intuitive software and user-friendly interface allow for efficient and streamlined examinations, maximizing patient comfort and clinic workflow.",
    desc: "Beyond the exceptional image quality and user experience, the Revo FC 130 stands out for its robust hardware design. Its reliable performance ensures consistent, dependable operation, which is vital in a busy clinical setting.The machine's durability fosters peace of mind, knowing it can withstand the demands of everyday use. I would also like to commend the excellent service team behind the Revo FC 130. Their prompt and helpful support has been invaluable, ensuring any questions or concerns are addressed swiftly and efficiently.The exceptional combination of superior technology, user-friendly design, and outstanding service makes the Revo FC 130 a truly remarkable OCT/angiography machine.",
    imgsrc: "/images/home/dr_shyamal.png",
    name: "Dr Shyamal Dwivedi",
    designation: "Vitreoretinal consultant",
    location: "Raghudeep Eye Hospital",
  },
];

export const textArray = [
  {
    heading: "Personal Information",
    paragraph:
      "As a general rule, this website does not collect Personal Information and sensitive personal data/information about user who visits the site.\n Any person can generally visit the site without revealing Personal Information, unless he / she choose to provide such information. Feedback/ Comment submitted by visitor(s) may include personal information such as name, email and contact number. This information is used strictly for business purposes. We do not provide or use personal information to unrelated businesses for direct marketing purposes.\n To the extent required or permitted by law, we may also collect, use and disclose personal information in connection with security related or law enforcement investigations or in the course of cooperating with authorities or complying with legal requirements.\n In addition, we may have collected similar information from you in the past. By entering this website you are consenting to the terms of our information privacy policy and to our continued use of previously collected information. By submitting your personal information to us, you will be treated as having given your permission for the processing of your personal data as set out in this policy.",
  },
  {
    heading: "Non personal information",
    paragraph:
      "At this web site, information sent by your web browser, may be automatically collected. This information typically includes your domain name (the site after the @ in your e-mail address). It may also contain your user name (the name before the @ in your e-mail address). Other examples of information collected by our server include the Internet protocol (IP) address used to connect the visitor's computer to the Internet, operating system and platform, the average time  spent  on  our  website,  pages  viewed,  information  searched  for,  access times, websites visited before and a visitor visits our website, and other relevant statistics. The amount of information sent depends on the settings you have on your web browser; please refer to your browser if you want to learn what information it sends. This website reads visits for visitors count. Myhealthskape never tracks or records information about individuals and their visits.",
  },
  {
    heading: "Cookies",
    paragraph: `A cookie is a piece of software code that an internet web site sends to user’s browser when he /she accesses information at that site. This website uses cookies but not for user information gathering.`,
  },
  {
    heading: "Copyright Policy",
    paragraph:
      "Contents of this website may not be reproduced partially or fully, without due permission from myhealthskape. If referred to as a part of another website, the source must be prominently acknowledged. The contents of this website cannot be used in any misleading, derogatory, objectionable context. The permissions to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorization to reproduce such material must be obtained from the copyright holders concerned. The software, text, images, graphics, video and audio used on this site belong to myhealthskape. No material from this site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without prior written permission from myhealthskape. All rights not expressly granted herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright and other applicable laws leading to criminal or civil penalties.",
  },
  {
    heading: "Hyperlinking Policy",
    paragraph:
      "Links to myhealthskape website by other websites Prior permission is required before hyperlinks are directed from any website/portal to this site. Permission for the same, stating the nature of the content on the pages from where the link has to be given and the exact language of the Hyperlink should be obtained by sending request to myhealthskape.\n Links to external websites / portals (If Any) \n At some of the places, this website contains links to other websites/portals. This links have been placed for user’s convenience. myhealthskape is not responsible for the contents and reliability of the links. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. myhealthskape cannot guarantee that these links will work all the time and myhealthskape has no control over availability of linked pages at all times.",
  },
  {
    heading: "Terms of Use",
    paragraph:
      "The official website of “myhealthskape has been developed to provide information to the general public. The documents and information displayed on the website are for reference purposes only and does not purport to be a legal document. Though all efforts have been made to ensure the accuracy of the content, the same should not be construed as a statement of law or used for any legal purposes.\n In case of any ambiguity or doubt, user(s) are advised to verify/check with the Ministry(s)  and/or  other  source(s),  and  to  obtain  appropriate  professional advice. \n In case of any variance between what has been stated and that contained in the relevant Act, Rules, Regulations, Policy Statements etc., the latter shall prevail.",
  },
  {
    heading: "Disclaimer",
    paragraph:
      "The information / material provided on the myhealthskape  website  is  provided  on  an  as  is  basis.  Myhealthskape expressly disclaims  all  warranties,  express  or  implied,  including,  but  not  limiting  to implied warranties of merchantability, fitness for a particular purpose and non- infringement. The company disclaims all responsibility for any loss, injury, liability or damage of any kind resulting from and arising out of, or any way related to:\n •   Any errors in or omissions from the website and its content, including but not limited to technical inaccuracies and typographical errors. \n •  Any third party websites or content therein directly or indirectly accessed through links in the site, including but not limited to any errors in or omissions there from.\n •  The unavailability of this site or any portion thereof. \n •  Use of any equipment or software in connection with the site. \n •  Use of the site. Limitation of  Liability \n •  Under No Circumstances shall myhealthskape be Liable to any user for: \n o Loss, injury, claim, liability or damages of any kind resulting from the use  of or  the inability  to  use  the materials  in  the website, regardless of the form of action, whether in contract, tort or otherwise. \n o Special, direct, incidental, punitive, exemplary or consequential damages of any kind whatsoever (including without limitation, attorney's fees) in any way due, resulting from or arising in connection with the use of or the inability to use the website or its content / materials. \n o Claim attributable to errors, omission or inaccuracies in or destructive properties of any information.",
  },
  {
    heading: "• No Liability",
    paragraph:
      "o myhealthskape makes reasonable effort to ensure  that  the  information provided on this website is accurate but does not guarantee or warrant its accuracy, adequacy, correctness, validity, completeness or suitability  for  any purpose. Use of this site, by implication, means that you have gone through and agreed to abide by the Terms and Conditions and the Disclaimers of this Site.\n o myhealthskape does not represent that the information downloaded from this website is completely error-free and accurate or the servers that make  myhealthskape  websites  available,  are  free  of  viruses  or  harmful components.  Although due  care  has  been  taken  to  make  the database completely reliable and error-free, the company claims exemption from any liability arising out of any such error in the data base.",
  },
  {
    heading: "• Myhealthskape Makes No Warranty That",
    paragraph:
      "o Any material downloaded and used for professional or other purposes shall be at the risk of the user and no services utilized by this   website   shall   create   any   warranty.   myhealthskape   shall   not   be responsible if any  information  /  page  is  downloaded  from  its website and after downloading complete / partial text / information is altered / removed / obscured contained therein.\n o myhealthskape makes no representations about the suitability, reliability, availability, and timeliness of the products and services contained on its website for any purpose.\n o In no event shall myhealthskape be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever, including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the company's websites, with the delay or inability to use its website or related services, the provision of or failure  to  provide  services  or  for  any  information,  software, products and services obtained through the company's website, or otherwise arising out of the use of the company's website, whether based on contract, negligence, strict liability or otherwise.",
  },
];

export const ourpaternes = [
  { id: 1, imgsrc: "/images/alliance/img3.png" },
  { id: 2, imgsrc: "/images/alliance/img1.png" },
  { id: 3, imgsrc: "/images/alliance/img2.png" },
  { id: 4, imgsrc: "/images/alliance/img4.png" },
  { id: 5, imgsrc: "/images/alliance/img5.png" },
  { id: 6, imgsrc: "/images/alliance/img6.png" },

  { id: 8, imgsrc: "/images/alliance/img11.png" },
  
  { id: 10, imgsrc: "/images/alliance/img16.png" },
  { id: 11, imgsrc: "/images/alliance/img8.png" },
  { id: 12, imgsrc: "/images/alliance/img9.png" },
  { id: 13, imgsrc: "/images/alliance/img10.png" },

  { id: 15, imgsrc: "/images/alliance/img13.png" },
  
];
