import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { ud } from "../../../Data/RectinaGlaucoma";

const Udcornea = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | UD-800"}
        centerHeading={"UD-800"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {ud.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  gap-y-40px-mvw md:gap-y-0 gap-x-[6vw] grid-cols-1 md:pb-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-[50%] max-w-[50%]">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-10/12 h-auto object-cover"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  PRODUCT MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-32px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-centergap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full   gap-x-16px-vw md:pt-[3vw] pt-60px-mvw gap-y-16px-mvw md:gap-y-0 mx-auto md:flex-row flex-col  flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item.video &&
              item.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>
                  <div className="w-full flex gap-y-40px-mvw md:gap-y-0 flex-col md:flex-row items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className=" w-full flex justify-center md:p-40px-vw items-center">
                        <img src={img} alt="" className="md:max-w-[25vw]" />
                      </div>
                    ))}
                  </div>

                  <div className="absolute left-0 md:w-full  md:mt-0   border-b-2 opacity-10  border-[#2B2A29] z-10"></div>
                  <h1 className="font-raleway font-bold md:text-32px-vw md:mt-40px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>

                  <div className="md:pt-60px-vw pt-60px-mvw flex flex-col items-start relative">
                    <div className="flex md:flex-row flex-col items-center md:gap-x-80px-vw">
                      {item.videoSrc.map((videoSrc, index) => (
                        <div key={index} className="mb-8">
                          {" "}
                          {/* Added margin-bottom for spacing between items */}
                          {/* <div className="absolute inset-0 flex items-center justify-center">
                            <img
                              src="/icon/playIcon.png"
                              alt="Play Icon"
                              className="w-[3.5vw] h-[3.5vw]"
                            />
                          </div> */}
                          <img
                            src={videoSrc.src}
                            alt={`video-${index}`} // Updated alt tag to make it unique
                            className="md:max-w-[42.24vw] block"
                          />
                          <div className="md:mt-15px-vw mt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-32px-vw text-16px-mvw">
                            {videoSrc.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Udcornea;
