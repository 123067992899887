import React, { useEffect } from "react";
import TopSection from "../../components/home/TopSection";
import ProductCategories from "../../components/home/ProductCategories";
import AboutSection from "../../components/home/AboutSection";
import OurPartners from "../../components/home/OurPartners";
import Testimonial from "../../components/home/Testimonial";

const Home = () => { 
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]) 

  
  return (
    <div className="w-full relative">
      <TopSection />
      <ProductCategories />
      <AboutSection />
      <OurPartners heading={"Our Partners"} />
      <Testimonial />
    </div>
  );
};

export default Home;
