import React, { useEffect } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import FooterSectionHeading from "../../components/common/FooterSectionHeading";
import Aos from "aos";
import { textArray } from "../../Data/DummyData";

const PrivacyPolicy = () => { 

  useEffect(()=>{
        window.scrollTo(0, 0);
      },[])  



  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <section className="w-full">
      <CategoryTopSection
        breadcrumb={"Home | Privacy Policy"}
        centerHeading={"Privacy Policy"}
      />

      <div
        className="w-full md:px-[7.29vw] px-16px-mvw py-60px-mvw md:py-[4vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {textArray.map((item, index) => (
          <div key={index} className="">
            <FooterSectionHeading headingText={item.heading} />
            <p className="text-[#2B2A29] pt-16px-mvw md:pt-0 font-poppins font-regular md:text-20px-vw text-16px-mvw  opacity-80">
              {item.paragraph.split("\n").map((line, lineIndex) => (
                <span key={lineIndex} className="">
                  {line}
                  <br />
                  <br />
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PrivacyPolicy;
