import { RxArrowLeft, RxArrowRight } from "react-icons/rx";
import React, { useEffect, useState } from "react";

const BottomSlider = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); 


  useEffect(() => {
    const imageUrls = slides.map((slide) => slide.videothumbnail);
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, [slides]);

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    setIsVideoPlaying(false);
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    setIsVideoPlaying(false);
  };

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  return (
    <div className="md:pt-0 px-16px-mvw md:px-0  pb-60px-mvw md:pb-[6vw] flex flex-col items-center relative">
      <h1 className="font-raleway uppercaase  font-bold md:text-32px-vw pb-20px-mvw md:pb-60px-vw text-24px-mvw text-[#2B2A29]">
        IMAGE & VIDEO GALLERY
      </h1>
      <div className="flex items-center gap-[2vw] md:gap-[0.83vw]">
        <div className="relative hidden md:block h-[40vw] w-[30vw] md:h-[26.04vw] md:w-[20.73vw]"> 
        <div className="bg-black absolute top-0 bottom-0 right-0 left-0  rounded-[1.04vw] opacity-60"></div>
          <img
            src={
              slides[(activeIndex - 1 + slides.length) % slides.length]
                .videothumbnail
            }
            alt="Previous"
            className="w-full h-full object-cover rounded-[3vw] md:rounded-[1.5vw]  transition-transform duration-300 ease-in-out"
          />

          <div className="absolute cursor-pointer left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div
              className="relative flex items-center justify-center w-[10vw] h-[10vw] md:w-[6.67vw] md:h-[6.67vw] rounded-full border-2 border-white bg-gradient-to-r from-[rgba(255,255,255,0.15)] to-[rgba(81,154,127,0.2)] backdrop-blur-[20px] shadow-[0px_8px_20px_rgba(0,0,0,0.2)] overflow-hidden"
              onClick={handlePrev}
            >
              <RxArrowLeft className="text-white text-[5vw] md:text-[3vw]" />
            </div>
          </div>
        </div>

        <div className="relative w-full md:h-[29.17vw] md:max-w-[42.24vw] md:w-[42.24vw] flex flex-col items-start justify-between">
          <div className="absolute cursor-pointer md:hidden top-[31vw] left-[1vw] transform -translate-y-1/2 -translate-x-1/2 z-10">
            <div
              className="relative flex items-center justify-center w-[10vw] h-[10vw] md:w-[6.67vw] md:h-[6.67vw] rounded-full border border-white bg-gradient-to-r from-[rgba(255,255,255,0.15)] to-[rgba(81,154,127,0.2)] backdrop-blur-[20px] shadow-[0px_8px_20px_rgba(0,0,0,0.2)] overflow-hidden cursor-pointer"
              onClick={handlePrev}
            >
              <RxArrowLeft className="text-white text-[5vw] md:text-[3vw]" />
            </div>
          </div>

          <div className="h-full w-full flex items-center justify-center">
            {isVideoPlaying ? (
              <iframe
                src={slides[activeIndex].videoSrc}
                title="Video Player"
                className="w-full h-full rounded-[3vw] md:rounded-[1.5vw]"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            ) : (
              <div
                className="relative w-full h-full cursor-pointer"
                onClick={handlePlayVideo}
              >
                <img
                  src={slides[activeIndex].videothumbnail}
                  alt="Active"
                  className="w-full h-full object-contain rounded-[3vw] md:rounded-[1.5vw]  transition-transform duration-300 ease-in-out"
                />
                {slides[activeIndex].videoSrc && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src="/icon/playIcon.png"
                      alt="Play Icon"
                      className="w-[7vw] h-[7vw] md:w-[3.5vw] md:h-[3.5vw]"
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {slides[activeIndex].videoTitle && (
            <div className="text-[#2B2A29] pt-[4vw] font-poppins font-medium text-18px-mvw md:pt-20px-vw md:text-32px-vw">
              {slides[activeIndex].videoTitle}
            </div>
          )}
          {slides[activeIndex].videoDesc && (
            <div className="text-[#2B2A29] pt-[3vw] font-regular font-poppins text-14px-mvw md:pt-12px-vw md:text-20px-vw opacity-80">
              {slides[activeIndex].videoDesc}
            </div>
          )}

          <div className="absolute cursor-pointer md:hidden top-[31vw] right-[2vw] transform -translate-y-1/2 translate-x-1/2 z-10">
            <div
              className="relative flex items-center justify-center w-[10vw] h-[10vw] md:w-[6.67vw] md:h-[6.67vw] rounded-full border border-white bg-gradient-to-r from-[rgba(255,255,255,0.15)] to-[rgba(81,154,127,0.2)] backdrop-blur-[20px] shadow-[0px_8px_20px_rgba(0,0,0,0.2)] overflow-hidden cursor-pointer"
              onClick={handleNext}
            >
              <RxArrowRight className="text-white text-[5vw] md:text-[3vw]" />
            </div>
          </div>
        </div>

        <div className="relative hidden md:block h-[40vw] w-[30vw] md:h-[26.04vw] md:w-[20.73vw]"> 
        <div className="bg-black absolute top-0 bottom-0 right-0 left-0  rounded-[1.04vw] opacity-60"></div>
          <img
            src={slides[(activeIndex + 1) % slides.length].videothumbnail}
            alt="Next"
            className="w-full h-full object-cover rounded-[3vw] md:rounded-[1.5vw]  transition-transform duration-300 ease-in-out"
          />

          <div className="absolute cursor-pointer left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div
              className="relative flex items-center justify-center w-[10vw] h-[10vw] md:w-[6.67vw] md:h-[6.67vw] rounded-full border-2 border-white bg-gradient-to-r from-[rgba(255,255,255,0.15)] to-[rgba(81,154,127,0.2)] backdrop-blur-[20px] shadow-[0px_8px_20px_rgba(0,0,0,0.2)] overflow-hidden"
              onClick={handleNext}
            >
              <RxArrowRight className="text-white text-[5vw] md:text-[3vw]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSlider;
